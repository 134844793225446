/*-----------------------------------------------------------------------------------

    Template Name: Ubani- Barber Shop Bootstrap 5 Template
	Author: Hastech IT
    Author URL: https://themeforest.net/user/hastech
    Support: https://hasthemes.com/contact-us/
    Description: Barber Shop Bootstrap 5 Template
    Version: 1.1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
	02. Component CSS
		2.1 - Button CSS
		2.2 - Form CSS
		2.3 - Blockquote CSS
		2.4 - Quick View CSS
	03. Header CSS
		3.1 - Header Top CSS
		3.2 - Header Bottom CSS
		3.3 - Header Mobile CSS
		3.4 - Off Canvas CSS
	04. Pages CSS (All Page Styles)
		4.1 - Slider CSS
		4.2 - Features CSS
		4.3 - About CSS
		4.4 - Product CSS
		4.5 - Banner CSS
		4.6 - Blog CSS
		4.7 - Testimonial CSS
		4.8 - Page Banner CSS
		4.9 - Shop CSS
		4.10 - Shop Single CSS
		4.11 - Cart CSS
		4.12 - Compare CSS
		4.13 - Checkout CSS
		4.14 - My Account CSS
		4.15 - Login & Register CSS
		4.16 - Blog Details CSS
		4.17 - Contact CSS
	05. Widget CSS
		5.1 - Sidebar Widget CSS
		5.2 - Footer Widget CSS
	07. Footer CSS


-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Karla", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.714;
  position: relative;
  visibility: visible;
  overflow-X: hidden;
  color: #707070;
}

body[dir="rtl"] {
  text-align: right;
}

p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

input,
button {
  outline: none;
}

input:focus,
button:focus {
  outline: none;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input {
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: 0;
}

a:focus, button:focus, img:focus, input:focus {
  outline: 0;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #cc2121;
}

ul, ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

button, input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
}

/* Section Style */
.section,
.section-fluid {
  float: left;
  width: 100%;
}

.section-fluid {
  padding-right: 45px;
  padding-left: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .section-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .section-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

.section-margin {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.section-margin-02 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin-02 {
    margin-top: 3 0px;
  }
}

@media only screen and (max-width: 767px) {
  .section-margin-02 {
    margin-top: 60px;
  }
}

.section-title .title {
  margin: 0;
  position: relative;
  text-transform: capitalize;
  vertical-align: top;
  font-weight: 700;
  font-size: 28px;
  color: #242424;
}

.section-title p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}

@media only screen and (max-width: 575px) {
  .section-title p br {
    display: none;
  }
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

@media only screen and (min-width: 1600px) {
  .custom-container {
    max-width: 1920px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1600px) {
  .ubani-slider-banner {
    padding: 30px;
    padding-bottom: 0;
  }
}

.bg-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none;
}

.bottom-bar {
  padding-top: 30px;
}

.bottom-bar p {
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
  margin: 0;
  margin-top: 25px;
  color: #222222;
  font-weight: 400;
  text-transform: capitalize;
}

.page-pagination {
  margin-top: 30px;
}

.page-pagination .pagination {
  margin-bottom: 0;
}

.page-pagination .pagination .page-item {
  margin: 0 2px;
}

.page-pagination .pagination .page-item .page-link {
  font-weight: 400;
  color: #242424;
  padding: 0;
  height: 38px;
  line-height: 38px;
  background: #f1f1f1;
  font-size: 14px;
  display: inline-block;
  width: 38px;
  border-radius: 100%;
  text-align: center;
  vertical-align: top;
  border: 0;
  transition: all 0.3s linear;
}

.page-pagination .pagination .page-item .page-link.active, .page-pagination .pagination .page-item .page-link:hover {
  background-color: #cc2121;
  color: #FFF;
}

/*----------------------------------------*/
/*  02. Component CSS
/*----------------------------------------*/
/*--
/*  2.2 - Button CSS
/*----------------------------------------*/
.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.btn-wrap .btn {
  margin: 5px;
}

.btn {
  display: inline-block;
  position: relative;
  background-color: transparent;
  color: #212529;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  font-family: "Karla", sans-serif;
  line-height: 28px;
  padding: 0px 25px;
  height: 50px;
  line-height: 48px;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.btn:active, .btn:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 16px;
    height: 45px;
    line-height: 43px;
  }
}

.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
}

.btn-group .btn {
  border-radius: 0;
}

.btn-group .btn:first-child {
  border-radius: 4px 0 0 4px;
}

.btn-group .btn:last-child {
  border-radius: 0 4px 4px 0;
}

.btn-round {
  border-radius: 100px;
}

.btn-square {
  border-radius: 0;
}

[class*="btn-icon-"] i {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 28px;
  text-align: center;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-icon-left {
  padding-left: 69px;
}

.btn-icon-left i {
  left: 0;
  right: auto;
}

.btn-icon-right {
  padding-right: 69px;
}

.btn-icon-right i {
  left: auto;
  right: 0;
}

.btn-box {
  width: 50px;
  padding: 10px;
  text-align: center;
  flex: 1 0 50px;
}

.btn-box i {
  display: block;
  line-height: 24px;
}

.btn-custom-01 {
  background-color: #6C757D;
  border-color: #6C757D;
  border-bottom: 2px solid #cc2121;
  color: #FFF;
}

.btn-custom-01:focus, .btn-custom-01:active, .btn-custom-01:hover {
  background-color: #cc2121;
  border-color: #cc2121;
  border-bottom-color: #6C757D;
  color: #FFF;
}

.btn-custom-02 {
  background-color: #cc2121;
  border-color: #cc2121;
  border-bottom: 2px solid #6C757D;
  color: #FFF;
}

.btn-custom-02:focus, .btn-custom-02:active, .btn-custom-02:hover {
  background-color: #6C757D;
  border-color: #6C757D;
  border-bottom-color: #cc2121;
  color: #FFF;
}

.btn-primary {
  background-color: #cc2121;
  border-color: #cc2121;
  color: #FFF;
}

.btn-primary:focus, .btn-primary:active, .btn-primary:hover {
  background-color: #cc2121;
  border-color: #cc2121;
  color: #FFF;
}

.btn-secondary {
  background-color: #6C757D;
  border-color: #6C757D;
  color: #FFF;
}

.btn-secondary:focus, .btn-secondary:active, .btn-secondary:hover {
  background-color: #6C757D;
  border-color: #6C757D;
  color: #FFF;
}

.btn-success {
  background-color: #198754;
  border-color: #198754;
  color: #FFF;
}

.btn-success:focus, .btn-success:active, .btn-success:hover {
  background-color: #198754;
  border-color: #198754;
  color: #FFF;
}

.btn-danger {
  background-color: #050505;
  border-color: #050505;
  color: #FFF;
}

.btn-danger:focus, .btn-danger:active, .btn-danger:hover {
  background-color: #050505;
  border-color: #050505;
  color: #FFF;
}

.btn-warning {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #242424;
}

.btn-warning:focus, .btn-warning:active, .btn-warning:hover {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #242424;
}

.btn-info {
  background-color: #0DCAF0;
  border-color: #0DCAF0;
  color: #FFF;
}

.btn-info:focus, .btn-info:active, .btn-info:hover {
  background-color: #0DCAF0;
  border-color: #0DCAF0;
  color: #FFF;
}

.btn-light {
  background-color: #F8F9FA;
  border-color: #F8F9FA;
  color: #242424;
}

.btn-light:focus, .btn-light:active, .btn-light:hover {
  background-color: #F8F9FA;
  border-color: #F8F9FA;
  color: #242424;
}

.btn-dark {
  background-color: #242424;
  border-color: #242424;
  color: #FFF;
}

.btn-dark:focus, .btn-dark:active, .btn-dark:hover {
  background-color: #242424;
  border-color: #242424;
  color: #FFF;
}

.btn-white {
  background-color: #FFF;
  border-color: #FFF;
  color: #242424;
}

.btn-white:focus, .btn-white:active, .btn-white:hover {
  background-color: #FFF;
  border-color: #FFF;
  color: #242424;
}

.btn-hover-primary:hover {
  background-color: #cc2121;
  border-color: #cc2121;
  color: #FFF;
}

.btn-hover-secondary:hover {
  background-color: #6C757D;
  border-color: #6C757D;
  color: #FFF;
}

.btn-hover-success:hover {
  background-color: #198754;
  border-color: #198754;
  color: #FFF;
}

.btn-hover-danger:hover {
  background-color: #050505;
  border-color: #050505;
  color: #FFF;
}

.btn-hover-warning:hover {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #242424;
}

.btn-hover-info:hover {
  background-color: #0DCAF0;
  border-color: #0DCAF0;
  color: #FFF;
}

.btn-hover-light:hover {
  background-color: #F8F9FA;
  border-color: #F8F9FA;
  color: #242424;
}

.btn-hover-dark:hover {
  background-color: #242424;
  border-color: #242424;
  color: #FFF;
}

.btn-hover-white:hover {
  background-color: #FFF;
  border-color: #FFF;
  color: #242424;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-android {
  background-color: #7AC157;
  border-color: #7AC157;
  color: #FFF;
}

.btn-android:hover {
  background-color: #6bba45;
  border-color: #6bba45;
}

.btn-apple {
  background-color: #B8B8B8;
  border-color: #B8B8B8;
  color: #FFF;
}

.btn-apple:hover {
  background-color: #ababab;
  border-color: #ababab;
}

.btn-behance {
  background-color: #1869FF;
  border-color: #1869FF;
  color: #FFF;
}

.btn-behance:hover {
  background-color: #0059fe;
  border-color: #0059fe;
}

.btn-codepen {
  background-color: #000000;
  border-color: #000000;
  color: #FFF;
}

.btn-codepen:hover {
  background-color: black;
  border-color: black;
}

.btn-dribbble {
  background-color: #EA4C8A;
  border-color: #EA4C8A;
  color: #FFF;
}

.btn-dribbble:hover {
  background-color: #e7357b;
  border-color: #e7357b;
}

.btn-dropbox {
  background-color: #007EE5;
  border-color: #007EE5;
  color: #FFF;
}

.btn-dropbox:hover {
  background-color: #0070cc;
  border-color: #0070cc;
}

.btn-evernote {
  background-color: #78D525;
  border-color: #78D525;
  color: #FFF;
}

.btn-evernote:hover {
  background-color: #6cbf21;
  border-color: #6cbf21;
}

.btn-facebook {
  background-color: #4867AA;
  border-color: #4867AA;
  color: #FFF;
}

.btn-facebook:hover {
  background-color: #405c98;
  border-color: #405c98;
}

.btn-github {
  background-color: #313131;
  border-color: #313131;
  color: #FFF;
}

.btn-github:hover {
  background-color: #242424;
  border-color: #242424;
}

.btn-google-drive {
  background-color: #1DA462;
  border-color: #1DA462;
  color: #FFF;
}

.btn-google-drive:hover {
  background-color: #198e55;
  border-color: #198e55;
}

.btn-googleearth {
  background-color: #4285F4;
  border-color: #4285F4;
  color: #FFF;
}

.btn-googleearth:hover {
  background-color: #2a75f3;
  border-color: #2a75f3;
}

.btn-google-glass {
  background-color: #EA4335;
  border-color: #EA4335;
  color: #FFF;
}

.btn-google-glass:hover {
  background-color: #e82e1e;
  border-color: #e82e1e;
}

.btn-google-maps {
  background-color: #5083C3;
  border-color: #5083C3;
  color: #FFF;
}

.btn-google-maps:hover {
  background-color: #4076ba;
  border-color: #4076ba;
}

.btn-google-play {
  background-color: #01B9FD;
  border-color: #01B9FD;
  color: #FFF;
}

.btn-google-play:hover {
  background-color: #01a6e4;
  border-color: #01a6e4;
}

.btn-google-plus {
  background-color: #DD5144;
  border-color: #DD5144;
  color: #FFF;
}

.btn-google-plus:hover {
  background-color: #d93d2e;
  border-color: #d93d2e;
}

.btn-google {
  background-color: #4285F4;
  border-color: #4285F4;
  color: #FFF;
}

.btn-google:hover {
  background-color: #2a75f3;
  border-color: #2a75f3;
}

.btn-instagram {
  background-color: #B23A94;
  border-color: #B23A94;
  color: #FFF;
}

.btn-instagram:hover {
  background-color: #9f3484;
  border-color: #9f3484;
}

.btn-css3 {
  background-color: #0277BD;
  border-color: #0277BD;
  color: #FFF;
}

.btn-css3:hover {
  background-color: #0267a4;
  border-color: #0267a4;
}

.btn-html5 {
  background-color: #E44D26;
  border-color: #E44D26;
  color: #FFF;
}

.btn-html5:hover {
  background-color: #d6411b;
  border-color: #d6411b;
}

.btn-javascript {
  background-color: #F9DC3D;
  border-color: #F9DC3D;
  color: #FFF;
}

.btn-javascript:hover {
  background-color: #f8d824;
  border-color: #f8d824;
}

.btn-python {
  background-color: #0C9DBF;
  border-color: #0C9DBF;
  color: #FFF;
}

.btn-python:hover {
  background-color: #0a89a7;
  border-color: #0a89a7;
}

.btn-lastfm {
  background-color: #E31B23;
  border-color: #E31B23;
  color: #FFF;
}

.btn-lastfm:hover {
  background-color: #cc181f;
  border-color: #cc181f;
}

.btn-linkedin {
  background-color: #007BB6;
  border-color: #007BB6;
  color: #FFF;
}

.btn-linkedin:hover {
  background-color: #006a9d;
  border-color: #006a9d;
}

.btn-paypal {
  background-color: #002F86;
  border-color: #002F86;
  color: #FFF;
}

.btn-paypal:hover {
  background-color: #00266d;
  border-color: #00266d;
}

.btn-pinterest {
  background-color: #BD081B;
  border-color: #BD081B;
  color: #FFF;
}

.btn-pinterest:hover {
  background-color: #a50718;
  border-color: #a50718;
}

.btn-pocket {
  background-color: #EF3E56;
  border-color: #EF3E56;
  color: #FFF;
}

.btn-pocket:hover {
  background-color: #ed2641;
  border-color: #ed2641;
}

.btn-polymer {
  background-color: #F87292;
  border-color: #F87292;
  color: #FFF;
}

.btn-polymer:hover {
  background-color: #f75a7f;
  border-color: #f75a7f;
}

.btn-rss {
  background-color: #F99C3A;
  border-color: #F99C3A;
  color: #FFF;
}

.btn-rss:hover {
  background-color: #f89021;
  border-color: #f89021;
}

.btn-share {
  background-color: #2C9CFF;
  border-color: #2C9CFF;
  color: #FFF;
}

.btn-share:hover {
  background-color: #1390ff;
  border-color: #1390ff;
}

.btn-stackoverflow {
  background-color: #F38024;
  border-color: #F38024;
  color: #FFF;
}

.btn-stackoverflow:hover {
  background-color: #f0720d;
  border-color: #f0720d;
}

.btn-steam {
  background-color: #15497B;
  border-color: #15497B;
  color: #FFF;
}

.btn-steam:hover {
  background-color: #113c65;
  border-color: #113c65;
}

.btn-twitter {
  background-color: #1DA1F2;
  border-color: #1DA1F2;
  color: #FFF;
}

.btn-twitter:hover {
  background-color: #0d95e8;
  border-color: #0d95e8;
}

.btn-vk {
  background-color: #5181B8;
  border-color: #5181B8;
  color: #FFF;
}

.btn-vk:hover {
  background-color: #4574aa;
  border-color: #4574aa;
}

.btn-wikipedia {
  background-color: #E9E9E9;
  border-color: #E9E9E9;
  color: #242424;
}

.btn-wikipedia:hover {
  background-color: gainsboro;
  border-color: gainsboro;
}

.btn-windows {
  background-color: #0078D6;
  border-color: #0078D6;
  color: #FFF;
}

.btn-windows:hover {
  background-color: #006abd;
  border-color: #006abd;
}

.btn-500px {
  background-color: #000000;
  border-color: #000000;
  color: #FFF;
}

.btn-500px:hover {
  background-color: black;
  border-color: black;
}

.btn-8tracks {
  background-color: #122D4B;
  border-color: #122D4B;
  color: #FFF;
}

.btn-8tracks:hover {
  background-color: #0d2136;
  border-color: #0d2136;
}

.btn-amazon {
  background-color: #F79B34;
  border-color: #F79B34;
  color: #FFF;
}

.btn-amazon:hover {
  background-color: #f68f1b;
  border-color: #f68f1b;
}

.btn-blogger {
  background-color: #F06A35;
  border-color: #F06A35;
  color: #FFF;
}

.btn-blogger:hover {
  background-color: #ee581d;
  border-color: #ee581d;
}

.btn-delicious {
  background-color: #0000FE;
  border-color: #0000FE;
  color: #FFF;
}

.btn-delicious:hover {
  background-color: #0000e5;
  border-color: #0000e5;
}

.btn-disqus {
  background-color: #2E9EFE;
  border-color: #2E9EFE;
  color: #FFF;
}

.btn-disqus:hover {
  background-color: #1592fe;
  border-color: #1592fe;
}

.btn-flattr {
  background-color: #7AB831;
  border-color: #7AB831;
  color: #FFF;
}

.btn-flattr:hover {
  background-color: #6da42c;
  border-color: #6da42c;
}

.btn-flickr {
  background-color: #FE0084;
  border-color: #FE0084;
  color: #FFF;
}

.btn-flickr:hover {
  background-color: #e50077;
  border-color: #e50077;
}

.btn-odnoklassniki {
  background-color: #F58220;
  border-color: #F58220;
  color: #FFF;
}

.btn-odnoklassniki:hover {
  background-color: #f1750b;
  border-color: #f1750b;
}

.btn-outlook {
  background-color: #0072C6;
  border-color: #0072C6;
  color: #FFF;
}

.btn-outlook:hover {
  background-color: #0063ad;
  border-color: #0063ad;
}

.btn-playstation {
  background-color: #07418E;
  border-color: #07418E;
  color: #FFF;
}

.btn-playstation:hover {
  background-color: #063676;
  border-color: #063676;
}

.btn-reddit {
  background-color: #FF4500;
  border-color: #FF4500;
  color: #FFF;
}

.btn-reddit:hover {
  background-color: #e63e00;
  border-color: #e63e00;
}

.btn-skype {
  background-color: #00A9F0;
  border-color: #00A9F0;
  color: #FFF;
}

.btn-skype:hover {
  background-color: #0097d7;
  border-color: #0097d7;
}

.btn-slideshare {
  background-color: #0077B5;
  border-color: #0077B5;
  color: #FFF;
}

.btn-slideshare:hover {
  background-color: #00669c;
  border-color: #00669c;
}

.btn-soundcloud {
  background-color: #FE4900;
  border-color: #FE4900;
  color: #FFF;
}

.btn-soundcloud:hover {
  background-color: #e54200;
  border-color: #e54200;
}

.btn-tumblr {
  background-color: #36465D;
  border-color: #36465D;
  color: #FFF;
}

.btn-tumblr:hover {
  background-color: #2d3a4d;
  border-color: #2d3a4d;
}

.btn-twitch {
  background-color: #6441A4;
  border-color: #6441A4;
  color: #FFF;
}

.btn-twitch:hover {
  background-color: #593a92;
  border-color: #593a92;
}

.btn-vimeo {
  background-color: #1AB7EA;
  border-color: #1AB7EA;
  color: #FFF;
}

.btn-vimeo:hover {
  background-color: #14a7d7;
  border-color: #14a7d7;
}

.btn-whatsapp {
  background-color: #189D0E;
  border-color: #189D0E;
  color: #FFF;
}

.btn-whatsapp:hover {
  background-color: #14860c;
  border-color: #14860c;
}

.btn-xbox {
  background-color: #107C0F;
  border-color: #107C0F;
  color: #FFF;
}

.btn-xbox:hover {
  background-color: #0d650c;
  border-color: #0d650c;
}

.btn-yahoo {
  background-color: #4101AF;
  border-color: #4101AF;
  color: #FFF;
}

.btn-yahoo:hover {
  background-color: #380196;
  border-color: #380196;
}

.btn-youtube {
  background-color: #FE0000;
  border-color: #FE0000;
  color: #FFF;
}

.btn-youtube:hover {
  background-color: #e50000;
  border-color: #e50000;
}

/*--
/*  2.2 - Form CSS	
/*----------------------------------------*/
.single-form {
  margin-top: 30px;
}

.single-form .form-control {
  height: 50px;
  border-radius: 5px;
  padding: 10px 15px;
  border-color: #ebebeb;
  font-size: 14px;
}

.single-form .form-control:focus {
  box-shadow: none;
  border-color: #cc2121;
}

.single-form .form-control + .form-control {
  margin-top: 20px;
}

.single-form textarea.form-control {
  height: 120px;
  resize: none;
}

.single-select2 {
  margin-top: 30px;
}

.single-select2 .form-select2 .select2 {
  width: 100%;
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single {
  height: 50px;
  border-color: #ebebeb;
  transition: all 0.3s linear;
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  width: 100%;
  line-height: 48px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 28px;
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
  width: 36px;
}

.single-select2 .form-select2 .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #cc2121;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #ebebeb;
}

.select2-dropdown {
  border-color: #ebebeb;
}

/*--
/*  2.3 - Blockquote CSS
/*----------------------------------------*/
blockquote {
  background-color: #f6f6f6;
  padding: 30px 45px;
  margin: 31px 0 31px 40px;
  border: 1px solid #ebebeb;
  border-left: 4px solid #cc2121;
  position: relative;
}

blockquote::before {
  content: "\eb0c";
  font-family: 'plazaicon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  color: #cc2121;
}

@media only screen and (max-width: 575px) {
  blockquote::before {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  blockquote {
    margin-left: 0;
    padding: 20px 25px;
  }
}

blockquote p {
  font-size: 16px;
  font-style: italic;
  color: #212529;
  line-height: 24px;
  font-weight: 400;
}

/*--
/*  2.4 - Quick View CSS
/*----------------------------------------*/
.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.modal .modal-dialog {
  width: calc(100% - 30px);
  max-width: 64rem;
}

.modal .modal-dialog .modal-content {
  position: relative;
  border: 0;
}

.modal .modal-dialog .modal-content .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 7;
}

.modal .modal-dialog .modal-content .btn-close:focus {
  box-shadow: none;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 30px;
}

@media only screen and (max-width: 575px) {
  .modal .modal-dialog .modal-content .modal-body {
    padding: 30px 20px 20px;
  }
}

.modal .modal-dialog .modal-content .modal-body .quick-view-image {
  margin-top: 30px;
}

.modal .modal-dialog .modal-content .modal-body .quick-view-content {
  margin-top: 30px;
}

.vertical-slider-wrap {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap {
    display: block;
  }
}

.vertical-slider-wrap .quick-view-slider {
  flex-basis: 90%;
  max-width: 85%;
  margin-left: 30px;
  cursor: crosshair;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .quick-view-slider {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.vertical-slider-wrap .quick-view-slider .product-label {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 7;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .quick-view-slider .product-label {
    top: 5px;
    left: 5px;
  }
}

.vertical-slider-wrap .quick-view-slider .product-label span {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #FFF;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  z-index: 4;
}

.vertical-slider-wrap .quick-view-slider .product-label .new {
  background-color: #1759df;
}

.vertical-slider-wrap .quick-view-slider .product-label .discount-percentage {
  background-color: #cc2121;
}

.vertical-slider-wrap .quick-view-slider:hover .btn-gallery {
  opacity: 1;
  visibility: visible;
}

.vertical-slider-wrap .quick-view-slider-nav {
  flex-basis: 15%;
  max-width: 15%;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .quick-view-slider-nav {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 10px;
  }
}

.vertical-slider-wrap .quick-view-slider-nav .swiper-wrapper {
  height: 100%;
}

.vertical-slider-wrap .quick-view-slider-nav .swiper-wrapper .swiper-slide {
  height: 100% !important;
  opacity: 0.5;
  cursor: pointer;
}

.vertical-slider-wrap .quick-view-slider-nav .swiper-wrapper .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.vertical-slider-wrap.vt-right-side {
  flex-direction: unset;
}

/*----------------------------------------*/
/*  03. Header CSS
/*----------------------------------------*/
.header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 93;
}

.header-section-02 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 93;
  padding-bottom: 0;
}

@media only screen and (min-width: 1600px) {
  .header-section-02 {
    padding: 30px;
  }
}

.header-section-02 .sticky {
  background-color: #292929;
}

/*--
/*  3.1 - Header Top CSS
/*----------------------------------------*/
.header-top {
  position: relative;
  padding: 24px 0;
}

.header-search {
  position: relative;
  max-width: 268px;
  width: 100%;
}

.header-search input {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  padding-right: 45px;
  border-radius: 50px;
  border: 0;
  background-color: #FFF;
}

.header-search button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 16px;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-actions > * + * {
  margin-left: 30px;
}

@media only screen and (max-width: 575px) {
  .header-actions > * + * {
    margin-left: 25px;
  }
}

.header-actions .header-action-btn {
  text-decoration: none;
  position: relative;
  line-height: 50px;
  display: inline-block;
}

.header-actions .header-action-btn i {
  font-size: 20px;
}

.header-actions .header-action-btn .action-num {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  color: #FFF;
  position: absolute;
  background: #cc2121;
  top: 0px;
  text-align: center;
  font-weight: 400;
  right: -12px;
  border-radius: 100%;
}

.header-actions .dropdown .dropdown-menu {
  min-width: 295px;
  width: 100%;
  border-radius: 0;
  background-color: #FFF;
  padding: 5px 25px 25px;
  top: 100% !important;
  transform: translate3d(0, 0, 0px) !important;
  left: auto !important;
  right: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-actions .dropdown .dropdown-menu {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .header-actions .dropdown .dropdown-menu {
    min-width: 200px;
  }
}

.header-actions .dropdown .dropdown-menu .dropdown-language,
.header-actions .dropdown .dropdown-menu .dropdown-currency {
  padding-top: 20px;
}

.header-actions .dropdown .dropdown-menu .selected {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #242424;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  text-transform: uppercase;
}

.header-actions .dropdown .dropdown-menu .selected img {
  margin-right: 5px;
}

.header-actions .dropdown .dropdown-menu ul {
  padding: 0;
  margin: 0;
}

.header-actions .dropdown .dropdown-menu ul > li {
  list-style: none;
}

.header-actions .dropdown .dropdown-menu ul > li > a {
  padding: 0 15px;
  color: #242424;
  line-height: 30px;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header-actions .dropdown .dropdown-menu ul > li > a img {
  margin-right: 5px;
}

.header-actions .dropdown .dropdown-cart {
  min-width: 340px;
  padding: 0;
}

.header-actions .dropdown .dropdown-cart .cart-price {
  overflow: hidden;
  padding: 25px;
  padding-bottom: 0;
}

.header-actions .dropdown .dropdown-cart .cart-price .cart-total .price-inline,
.header-actions .dropdown .dropdown-cart .cart-price .cart-subtotals .price-inline {
  display: flex;
  justify-content: space-between;
}

.header-actions .dropdown .dropdown-cart .cart-price .cart-total .price-inline span,
.header-actions .dropdown .dropdown-cart .cart-price .cart-subtotals .price-inline span {
  font-weight: 400;
  color: #888888;
  font-size: 14px;
  line-height: 1.785;
}

.header-actions .dropdown .dropdown-cart .cart-price .cart-total .price-inline span.label,
.header-actions .dropdown .dropdown-cart .cart-price .cart-subtotals .price-inline span.label {
  color: #222222;
}

.header-actions .dropdown .dropdown-cart .cart-price .cart-total .price-inline span {
  font-weight: 500;
  color: #222222;
}

.header-actions .dropdown .dropdown-cart .checkout-btn {
  padding: 25px;
}

.header-actions .dropdown .dropdown-cart .checkout-btn .btn {
  border-radius: 0;
  border: 2px solid #eeeeee;
  height: 50px;
  line-height: 46px;
  font-size: 13px;
  font-weight: 500;
}

.header-actions .dropdown .dropdown-cart .checkout-btn .btn:hover {
  border-color: #cc2121;
}

.header-actions .dropdown .dropdown-search {
  border: 0;
  background: none;
  padding: 0;
}

.header-actions .dropdown .dropdown-search .header-search {
  max-width: 100%;
}

.header-actions-02 .header-action-btn {
  color: #FFF;
}

.cart-content {
  max-height: 278px;
  overflow: auto;
  padding: 5px 25px;
}

.cart-content .cart-items li {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
}

.single-cart-item {
  display: flex;
}

.single-cart-item .cart-item-content {
  padding-left: 15px;
  padding-right: 20px;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.single-cart-item .cart-item-content .product-name {
  color: #242424;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.single-cart-item .cart-item-content .product-price {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
}

.single-cart-item .cart-item-content .attributes-content {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #747474;
  margin-top: 5px;
}

.single-cart-item .cart-item-content .attributes-content span {
  font-size: 14px;
  display: block;
}

.single-cart-item .cart-item-content .attributes-content span strong {
  font-weight: 400;
}

.single-cart-item .cart-item-content .remove-cart {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
}

.header-search-action {
  display: flex;
  justify-content: flex-end;
}

.header-search-action > * + * {
  margin-left: 30px;
}

@media only screen and (max-width: 575px) {
  .header-search-action > * + * {
    margin-left: 25px;
  }
}

/*--
/*  3.2 - Header Bottom CSS
/*----------------------------------------*/
.header-bottom {
  position: relative;
}

.primary-menu {
  border-top: 1px solid #dadada;
  position: relative;
}

.primary-menu ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.primary-menu ul > li {
  list-style: none;
  position: relative;
}

.primary-menu ul > li > a {
  text-decoration: none;
  display: block;
  padding: 20px 0;
  margin: 0 20px;
  font-weight: 700;
  font-size: 14px;
  color: #242424;
  background: transparent;
  text-transform: uppercase;
  line-height: 30px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.primary-menu ul > li.menu-item-has-children > a::after {
  content: "\e92e";
  font-family: 'plazaicon';
  margin-left: 10px;
  font-weight: 400;
}

.primary-menu ul > li.active > a {
  color: #cc2121;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .primary-menu ul > li:nth-last-of-type(1) .sub-menu li .sub-menu, .primary-menu ul > li:nth-last-of-type(2) .sub-menu li .sub-menu {
    right: 100%;
    left: auto;
  }
}

.primary-menu ul > li:hover > a {
  color: #cc2121;
}

.primary-menu ul > li:hover > .mega-sub-menu,
.primary-menu ul > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.primary-menu .sub-menu {
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  border: 1px solid #e8e8e8;
  background-color: #FFF;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0% 0%;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

.primary-menu .sub-menu li a {
  margin: 0;
  padding: 5px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 13px;
  font-weight: 400;
  color: #555555;
}

.primary-menu .sub-menu li:last-child > a {
  border-bottom: 0;
}

.primary-menu .sub-menu li.menu-item-has-children > a::after {
  content: "\e930";
  font-family: 'plazaicon';
  margin-left: 10px;
  float: right;
  display: block;
}

.primary-menu .sub-menu li .sub-menu {
  left: 100%;
  top: -1px;
}

.primary-menu .sub-menu li:hover > a {
  padding-left: 30px;
}

.primary-menu .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.primary-menu .mega-sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1140px;
  border: 1px solid #eeeeee;
  background-color: #FFF;
  z-index: 9;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0% 0%;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
  box-sizing: border-box;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .primary-menu .mega-sub-menu {
    width: 930px;
  }
}

.primary-menu .mega-sub-menu > li {
  width: 25%;
}

.primary-menu .mega-sub-menu > li .menu-title {
  color: #292929;
  line-height: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin: 0;
  padding: 0;
}

.primary-menu .mega-sub-menu > li .menu-title::after {
  display: none;
}

.primary-menu .mega-sub-menu > li .menu-item {
  flex-direction: column;
  margin-top: 30px;
}

.primary-menu .mega-sub-menu > li .menu-item > li > a {
  padding: 0;
  margin: 0;
  color: #707070;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.primary-menu .mega-sub-menu > li .menu-item > li:hover > a {
  padding-left: 10px;
  color: #cc2121;
}

.primary-menu-02 {
  border-top: 0;
  position: static;
}

.primary-menu-02 ul {
  justify-content: flex-start;
}

.primary-menu-02 ul > li > a {
  color: #FFF;
}

.primary-menu-02 ul > li.active > a {
  color: #cc2121;
}

.primary-menu-02 ul > li:hover > a {
  color: #cc2121;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 220;
  animation: sticky 1s;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  background-color: #FFF;
  padding: 0;
}

.sticky > .primary-menu > ul > li > a {
  padding: 15px 0;
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/*--
/*  3.3 - Header Mobile CSS
/*----------------------------------------*/
.header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 93;
  background-color: #FFF;
}

.header-mobile-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 93;
  background-color: #242424;
}

.header-mobile-02 .sticky {
  background-color: rgba(41, 41, 41, 0.8);
}

.header-mobile-top {
  padding: 10px 0;
}

.header-toggle .mobile-menu-open {
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle .mobile-menu-open span {
  width: 23px;
  height: 2px;
  background-color: #242424;
  margin: 5px 0;
  display: block;
}

.header-toggle-02 .mobile-menu-open span {
  background-color: #FFF;
}

.header-mobile-bootm {
  padding-bottom: 15px;
}

.header-mobile-bootm .header-search {
  max-width: 100%;
}

.header-mobile-bootm .header-search input {
  background-color: #f3f3f3;
}

/*--
/*  3.4 - Off Canvas CSS
/*----------------------------------------*/
.off-canvas-box {
  position: fixed;
  top: 0;
  left: -320px;
  width: 320px;
  height: 100%;
  background-color: #FFF;
  z-index: 967;
  overflow-y: auto;
  transition: all 0.3s linear;
}

.off-canvas-box.open {
  left: 0;
}

.canvas-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-bottom: 1px solid #eeeeee;
}

.canvas-action .action {
  font-weight: 400;
  color: #888888;
  background: transparent;
  text-shadow: none;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  text-transform: capitalize;
  text-decoration: none;
  margin: 0 15px;
  transition: all 0.3s linear;
}

.canvas-action .action:hover {
  color: #cc2121;
}

.canvas-close-bar {
  background-color: #242424;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
}

.canvas-close-bar a {
  text-decoration: none;
  color: #FFF;
  font-size: 18px;
}

.canvas-menu {
  padding-bottom: 30px;
}

.canvas-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.canvas-menu ul li {
  position: relative !important;
  display: block;
  line-height: 1;
}

.canvas-menu ul li a {
  display: block;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  padding-right: 50px;
  line-height: 30px;
  text-transform: capitalize;
  border-top: 1px solid #eeeeee;
  text-decoration: none;
}

.canvas-menu ul li .mobile-menu-expand {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 13px;
  right: 21px;
  cursor: pointer;
}

.canvas-menu ul li .mobile-menu-expand::before {
  position: absolute;
  content: '';
  width: 13px;
  height: 1px;
  background-color: #242424;
  top: 7px;
  left: 1px;
}

.canvas-menu ul li .mobile-menu-expand::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 13px;
  background-color: #242424;
  left: 7px;
  bottom: 2px;
  transition: all 0.1s linear;
}

.canvas-menu ul li.active-expand > .mobile-menu-expand::after {
  height: 0;
}

.canvas-menu ul li ul li a {
  padding-left: 11%;
}

.canvas-menu ul li ul li ul li a {
  padding-left: 16%;
}

.canvas-menu ul li ul li ul li ul li a {
  padding-left: 21%;
}

.canvas-menu ul li ul li ul li ul li ul li a {
  padding-left: 26%;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s linear;
}

.menu-overlay.open {
  opacity: 0.6;
  visibility: visible;
}

/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/
/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/
.single-slider {
  height: 936px;
  display: flex;
  align-items: center;
  background-position: center center;
  background-size: cover;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-slider {
    height: 820px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider {
    height: 720px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider {
    height: 680px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider {
    height: 580px;
  }
}

.slider-content {
  max-width: 630px;
  padding-top: 135px;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content {
    max-width: 430px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content {
    padding-top: 114px;
    max-width: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content {
    max-width: 70%;
    padding-top: 125px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content {
    max-width: 100%;
  }
}

.slider-content .sub-title {
  font-size: 24px;
  color: #666666;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .sub-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .sub-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .sub-title {
    font-size: 16px;
  }
}

.slider-content .main-title {
  font-size: 80px;
  color: #242424;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .main-title {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .main-title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .main-title {
    font-size: 38px;
  }
}

.slider-content p {
  font-size: 21px;
  color: #666;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content p {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

.slider-content .btn {
  text-transform: uppercase;
  border-radius: 100px;
  height: 65px;
  line-height: 63px;
  font-weight: 700;
  padding: 0 50px;
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .btn {
    height: 55px;
    line-height: 53px;
    padding: 0 30px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .btn {
    height: 55px;
    line-height: 53px;
    padding: 0 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .btn {
    height: 45px;
    line-height: 43px;
    padding: 0 30px;
    font-size: 14px;
  }
}

.slider-content-02 .sub-title {
  color: #FFF;
}

.slider-content-02 .main-title {
  color: #FFF;
}

.slider-content-02 p {
  color: #FFF;
}

.slider-content-03 {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-03 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-03 {
    padding-bottom: 30px;
  }
}

.slider-content-03 .sub-title {
  color: #FFF;
}

.slider-content-03 .main-title {
  color: #FFF;
}

.slider-content-03 p {
  color: #FFF;
}

.slider-active .swiper-pagination {
  bottom: 30px;
}

.slider-active .swiper-pagination .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  transform: scale(0.8);
  background-color: #d4d4d4;
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.slider-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  transform: scale(1.3);
  background-color: #cc2121;
}

.slider-active .swiper-pagination .swiper-pagination-bullet:hover {
  transform: scale(1.3);
  background-color: #cc2121;
}

.slider-active .swiper-button-next,
.slider-active .swiper-button-prev {
  color: #FFF;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.slider-active .swiper-button-next::after,
.slider-active .swiper-button-prev::after {
  font-size: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active .swiper-button-next::after,
  .slider-active .swiper-button-prev::after {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-active .swiper-button-next::after,
  .slider-active .swiper-button-prev::after {
    font-size: 24px;
  }
}

.slider-active:hover .swiper-button-next,
.slider-active:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.slider-active:hover .swiper-button-prev {
  left: 30px;
}

.slider-active:hover .swiper-button-next {
  right: 30px;
}

.animation-style-01.swiper-slide-active .slider-content {
  opacity: 1;
}

.animation-style-01.swiper-slide-active .slider-content .sub-title {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content .main-title {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content p {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content .btn {
  animation-name: fadeInLeft;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

/*--
/*  4.2 - Features CSS
/*----------------------------------------*/
.features-section {
  border-bottom: 1px solid #e8e8e8;
}

.single-features-item {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-features-item {
    display: block;
    text-align: center;
  }
}

.single-features-item .feature-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-features-item .feature-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

.single-features-item .feature-content .title {
  color: #242424;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-features-item .feature-content .title {
    margin-bottom: 15px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-features-item .feature-content .title {
    margin-bottom: 15px;
  }
}

.single-features-item .feature-content p {
  font-weight: 400;
  display: block;
  margin: 0;
  font-size: 14px;
  margin-bottom: 0;
}

/*--
/*  4.3 - About CSS
/*----------------------------------------*/
.about-image img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-text {
    position: relative;
    width: 100%;
    margin-top: 45px;
  }
}

.about-text .title {
  font-size: 46px;
  color: #242424;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-text .title {
    font-size: 28px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-text .title {
    font-size: 28px;
  }
}

.about-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-text p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .about-text p {
    font-size: 14px;
  }
}

.about-text p a {
  text-decoration: none;
  color: #cc2121;
}

@media only screen and (min-width: 1600px) {
  .about-text .about-text-wrapper {
    padding-left: 60px;
  }
}

.about-text img {
  padding-top: 30px;
}

/*--
/*  4.4 - Product CSS
/*----------------------------------------*/
.product-item {
  padding: 15px;
  transition: all 300ms linear;
  background-color: #FFF;
  position: relative;
}

.product-item .image-block {
  position: relative;
}

.product-item .image-block a {
  display: block;
  position: relative;
}

.product-item .image-block img {
  width: 100%;
  transition: all 0.6s linear;
}

.product-item .image-block img.image-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.6s linear;
  opacity: 0;
  visibility: hidden;
}

.product-item .image-block .product-label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 7;
}

.product-item .image-block .product-label span {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #FFF;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  z-index: 4;
}

.product-item .image-block .product-label .new {
  background-color: #1759df;
}

.product-item .image-block .product-label .discount-percentage {
  background-color: #cc2121;
}

.product-item .image-block .product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  transform: translateY(25px) translateZ(0);
  perspective: 800px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
  -webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
  transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
}

.product-item .image-block .product-action .action-btn {
  display: inline-block;
  background: #FFF;
  color: #888888;
  padding: 0;
  border: none;
  line-height: 50px;
  height: 50px;
  width: 50px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s linear;
  position: relative;
}

.product-item .image-block .product-action .action-btn + .action-btn::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 25px;
  background: #ebebeb;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-item .image-block .product-action .action-btn {
    line-height: 40px;
    height: 40px;
    width: 40px;
  }
}

.product-item .image-block .product-action .action-btn:hover {
  color: #cc2121;
}

.product-item .product-desc {
  padding: 12px 0 0;
  background-color: #FFF;
  overflow: hidden;
  position: relative;
  z-index: 2;
  transition: all 300ms linear;
}

.product-item .product-desc .product-features-reviews {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .product-item .product-desc .product-features-reviews {
    margin-bottom: 5px;
  }
}

.product-item .product-desc .features {
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
}

.product-item .product-desc .review-star {
  position: relative;
  display: inline-block;
}

.product-item .product-desc .review-star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.product-item .product-desc .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.product-item .product-desc .review-star .star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.product-item .product-desc .product-name {
  line-height: 1;
}

.product-item .product-desc .product-name a {
  text-decoration: none;
  font-weight: 600;
  color: #242424;
  font-size: 16px;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-item .product-desc .product-name a {
    font-size: 14px;
  }
}

.product-item .product-desc .product-name a:hover {
  color: #cc2121;
  text-decoration: underline;
}

.product-item .product-desc .product-price {
  line-height: 1;
  margin-top: 12px;
  display: inline-block;
  width: 100%;
}

.product-item .product-desc .product-price .sale-price {
  color: #cc2121;
  font-size: 18px;
  font-weight: 500;
}

.product-item .product-desc .product-price .old-price {
  color: #888888;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 14px;
}

.product-item .product-desc .desc-text {
  display: block;
  padding: 20px 0;
  margin: 20px 0 0;
  border-top: 1px solid #ebebeb;
  color: #5a5a5a;
  font-size: 14px;
  line-height: 24px;
}

.product-item .product-desc .availability p {
  font-size: 14px;
  color: #242424;
}

.product-item.product-list-item {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .product-item.product-list-item {
    display: block;
  }
}

.product-item.product-list-item .image-block {
  width: 33.33%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .product-item.product-list-item .image-block {
    width: 100%;
  }
}

.product-item.product-list-item .product-desc {
  flex: 1;
  padding-top: 0;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .product-item.product-list-item .product-desc {
    padding-top: 15px;
    padding-left: 0;
  }
}

.product-item.product-list-item .product-desc .product-name a {
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-item.product-list-item .product-desc .product-name a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .product-item.product-list-item .product-desc .product-name a {
    font-size: 14px;
  }
}

.product-item:hover {
  box-shadow: 0 0 20px 0 rgba(41, 44, 58, 0.15);
  z-index: 5;
}

.product-item:hover .image-block img:not(:last-child) {
  opacity: 0;
  visibility: hidden;
}

.product-item:hover .image-block img.image-hover {
  opacity: 1;
  visibility: visible;
}

.product-item:hover .image-block .product-action {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}

.swiper-slide:not(.swiper-slide-visible) .product-item:hover {
  box-shadow: none !important;
}

.product-wrapper {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}

.product-wrapper .product-item {
  margin-top: 30px;
}

.product-active-2 .swiper-container,
.product-active .swiper-container {
  padding: 15px;
  padding-top: 0;
}

.product-active-2 .swiper-button-prev,
.product-active-2 .swiper-button-next,
.product-active .swiper-button-prev,
.product-active .swiper-button-next {
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  left: -10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .product-active-2 .swiper-button-prev,
  .product-active-2 .swiper-button-next,
  .product-active .swiper-button-prev,
  .product-active .swiper-button-next {
    left: 0;
  }
}

.product-active-2 .swiper-button-prev::after,
.product-active-2 .swiper-button-next::after,
.product-active .swiper-button-prev::after,
.product-active .swiper-button-next::after {
  font-size: 30px;
  color: #e2e2e2;
  transition: all 0.3s linear;
}

.product-active-2 .swiper-button-prev:hover::after,
.product-active-2 .swiper-button-next:hover::after,
.product-active .swiper-button-prev:hover::after,
.product-active .swiper-button-next:hover::after {
  color: #cc2121;
}

@media only screen and (max-width: 575px) {
  .product-active-2 .swiper-button-prev,
  .product-active-2 .swiper-button-next,
  .product-active .swiper-button-prev,
  .product-active .swiper-button-next {
    left: 0;
  }
}

.product-active-2 .swiper-button-next,
.product-active .swiper-button-next {
  left: auto;
  right: -10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .product-active-2 .swiper-button-next,
  .product-active .swiper-button-next {
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .product-active-2 .swiper-button-next,
  .product-active .swiper-button-next {
    right: 0;
  }
}

.product-active-2:hover .swiper-button-prev,
.product-active-2:hover .swiper-button-next,
.product-active:hover .swiper-button-prev,
.product-active:hover .swiper-button-next {
  opacity: 1;
  visibility: visible;
}

.product-tabs .nav .nav-item {
  padding: 0 30px;
}

@media only screen and (max-width: 575px) {
  .product-tabs .nav .nav-item {
    padding: 0 15px;
  }
}

.product-tabs .nav .nav-item a {
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tabs .nav .nav-item a {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tabs .nav .nav-item a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .product-tabs .nav .nav-item a {
    font-size: 15px;
  }
}

.product-tabs .nav .nav-item a.active {
  color: #cc2121;
}

/*--
/*  4.5 - Banner CSS
/*----------------------------------------*/
.banner-wrapper-02 {
  position: relative;
  z-index: 9;
  padding: 0 30px;
  margin-top: -100px;
  background-color: #FFF;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrapper-02 {
    margin-top: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrapper-02 {
    margin-top: 30px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrapper-02 {
    margin-top: 30px;
    padding: 0;
  }
}

.banner-box {
  margin-top: 30px;
}

.banner-box a {
  overflow: hidden;
  display: block;
}

.banner-box a img {
  transition: all 0.3s linear;
  width: 100%;
}

.banner-box:hover a img {
  transform: scale(1.1);
}

/*--
/*  4.6 - Blog CSS
/*----------------------------------------*/
.blog-wraper {
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-wraper {
    padding-top: 10px;
  }
}

.blog-wraper-2 {
  padding-top: 20px;
}

.single-blog-item {
  margin-top: 30px;
}

.single-blog-item .blog-image a {
  display: block;
  overflow: hidden;
}

.single-blog-item .blog-image a img {
  transition: all 0.3s linear;
  width: 100%;
}

.single-blog-item .gallery-active .swiper-button-next,
.single-blog-item .gallery-active .swiper-button-prev {
  color: #FFF;
  transition: all 0.3s linear;
}

.single-blog-item .gallery-active .swiper-button-next::after,
.single-blog-item .gallery-active .swiper-button-prev::after {
  font-size: 30px;
}

.single-blog-item .gallery-active .swiper-button-next:hover,
.single-blog-item .gallery-active .swiper-button-prev:hover {
  color: #cc2121;
}

.single-blog-item .blog-embed .ratio-16x9 {
  --bs-aspect-ratio: calc(9.85 / 15 * 100%);
}

.single-blog-item .blog-embed .ratio-21x9 {
  --bs-aspect-ratio: calc(11 / 21 * 100%);
}

.single-blog-item .blog-content {
  padding-top: 25px;
}

.single-blog-item .blog-content .post-title a {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  color: #292929;
  text-transform: capitalize;
  position: relative;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s linear;
  text-decoration: none;
}

.single-blog-item .blog-content .post-title a:hover {
  color: #cc2121;
}

.single-blog-item .blog-content .blog-meta {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.single-blog-item .blog-content .blog-meta > p {
  font-size: 14px;
  color: #9d9d9d;
  margin-bottom: 0;
}

.single-blog-item .blog-content .blog-meta > p + p::before {
  content: "/";
  display: inline-block;
  margin: 0 7px;
}

.single-blog-item .blog-content .blog-meta > p a {
  text-decoration: none;
  color: #cc2121;
}

.single-blog-item .blog-content > p {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
  margin-top: 18px;
}

.single-blog-item:hover .blog-image a img {
  transform: scale(1.1);
}

.blog-active {
  position: relative;
}

.blog-active .swiper-button-prev,
.blog-active .swiper-button-next {
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .blog-active .swiper-button-prev,
  .blog-active .swiper-button-next {
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .blog-active .swiper-button-prev,
  .blog-active .swiper-button-next {
    left: 0;
  }
}

.blog-active .swiper-button-prev::after,
.blog-active .swiper-button-next::after {
  font-size: 30px;
  color: #e2e2e2;
  transition: all 0.3s linear;
}

.blog-active .swiper-button-prev:hover::after,
.blog-active .swiper-button-next:hover::after {
  color: #cc2121;
}

.blog-active .swiper-button-next {
  left: auto;
  right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .blog-active .swiper-button-next {
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .blog-active .swiper-button-next {
    right: 0;
  }
}

.blog-active:hover .swiper-button-prev,
.blog-active:hover .swiper-button-next {
  opacity: 1;
  visibility: visible;
}

/*--
/*  4.7 - Testimonial CSS
/*----------------------------------------*/
.single-testimonial {
  max-width: 870px;
  margin: 0 auto;
}

.single-testimonial .testimonial-avater img {
  border-radius: 50%;
  display: inline-block;
}

.single-testimonial .testimonial-content {
  padding-top: 35px;
}

.single-testimonial .testimonial-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 2.125;
  text-align: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .single-testimonial .testimonial-content p {
    font-size: 14px;
  }
}

.single-testimonial .testimonial-content .name {
  color: #292929;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.single-testimonial .testimonial-content .designation {
  text-align: center;
  color: #929292;
  font-size: 14px;
  margin-top: 15px;
}

.testimonial-active {
  position: relative;
}

.testimonial-active .swiper-button-next,
.testimonial-active .swiper-button-prev {
  color: #242424;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.testimonial-active .swiper-button-next::after,
.testimonial-active .swiper-button-prev::after {
  font-size: 36px;
}

@media only screen and (max-width: 575px) {
  .testimonial-active .swiper-button-next::after,
  .testimonial-active .swiper-button-prev::after {
    font-size: 20px;
  }
}

.testimonial-active:hover .swiper-button-next,
.testimonial-active:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.testimonial-active:hover .swiper-button-prev {
  left: 30px;
}

.testimonial-active:hover .swiper-button-next {
  right: 30px;
}

/*--
/*  4.8 - Page Banner CSS
/*----------------------------------------*/
.page-banner-section {
  background-color: #f3f3f3;
  padding-top: 225px;
  padding-bottom: 60px;
}

.page-banner-content .page-banner-title {
  font-size: 40px;
  color: #242424;
  text-transform: capitalize;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-content .page-banner-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-content .page-banner-title {
    font-size: 24px;
  }
}

.page-banner-content .breadcrumb {
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  margin-top: 25px;
}

.page-banner-content .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.page-banner-content .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\e930";
  color: #666;
  margin: 0 15px;
  font-size: 14px;
  vertical-align: -1px;
  font-family: 'plazaicon';
  padding: 0;
}

.page-banner-content .breadcrumb .breadcrumb-item a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s linear;
}

.page-banner-content .breadcrumb .breadcrumb-item a:hover {
  color: #cc2121;
}

/*--
/*  4.9 - Shop CSS
/*----------------------------------------*/
.shop-top-bar {
  padding-top: 30px;
}

.shop-top-content {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.shop-top-content .nav {
  margin-right: 50px;
  position: relative;
  z-index: 1;
}

.shop-top-content .nav li button {
  font-size: 21px;
  line-height: 30px;
  height: 30px;
  margin-right: 15px;
  color: #dbdbdb;
  background: transparent;
  transition: all 300ms linear;
  padding: 0;
  border: 0;
  background: none;
}

.shop-top-content .nav li button:hover, .shop-top-content .nav li button.active {
  color: #cc2121;
}

.shop-top-content p {
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
  margin: 0;
  color: #222222;
  font-weight: 400;
  text-transform: capitalize;
}

.shop-top-content select {
  width: 370px;
  padding: 2px 15px;
  border: 1px solid #ebebeb;
  font-size: 13px;
  height: 30px;
  outline: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-top-content select {
    width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-top-content select {
    width: 210px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-top-content select {
    width: 210px;
  }
}

.shop-grid-items,
.shop-list-items {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 30px;
}

/*--
/*  4.10 - Shop Single CSS
/*----------------------------------------*/
.vertical-slider-wrap {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap {
    display: block;
  }
}

.vertical-slider-wrap .pd-vertical-slider {
  flex-basis: 90%;
  max-width: 85%;
  margin-left: 30px;
  cursor: crosshair;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .pd-vertical-slider {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.vertical-slider-wrap .pd-vertical-slider .product-label {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 7;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .pd-vertical-slider .product-label {
    top: 5px;
    left: 5px;
  }
}

.vertical-slider-wrap .pd-vertical-slider .product-label span {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #FFF;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  z-index: 4;
}

.vertical-slider-wrap .pd-vertical-slider .product-label .new {
  background-color: #1759df;
}

.vertical-slider-wrap .pd-vertical-slider .product-label .discount-percentage {
  background-color: #cc2121;
}

.vertical-slider-wrap .pd-vertical-slider .btn-gallery {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  font-size: 24px;
  background-color: #FFF;
  color: #e2e2e2;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .pd-vertical-slider .btn-gallery {
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
}

.vertical-slider-wrap .pd-vertical-slider:hover .btn-gallery {
  opacity: 1;
  visibility: visible;
}

.vertical-slider-wrap .vertical-slider-nav {
  flex-basis: 15%;
  max-width: 15%;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  .vertical-slider-wrap .vertical-slider-nav {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 10px;
  }
}

.vertical-slider-wrap .vertical-slider-nav .swiper-wrapper {
  height: 100%;
}

.vertical-slider-wrap .vertical-slider-nav .swiper-wrapper .swiper-slide {
  height: 100% !important;
  opacity: 0.5;
  cursor: pointer;
}

.vertical-slider-wrap .vertical-slider-nav .swiper-wrapper .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.vertical-slider-wrap.vt-right-side {
  flex-direction: unset;
}

.shop-gallery-image {
  margin-top: 50px;
}

.shop-gallery-image .single-gallery-image img {
  width: 100%;
}

.gallery-hidden {
  display: none;
}

.shop-single-content {
  margin-top: 48px;
}

.shop-single-content.shop-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  left: 0;
  width: 100%;
}

.shop-single-content .product-name {
  color: #242424;
  font-size: 24px;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-single-content .product-name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-single-content .product-name {
    font-size: 20px;
  }
}

.shop-single-content .reference {
  margin-bottom: 10px;
}

.shop-single-content .shop-rating-content {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shop-single-content .shop-rating-content li {
  margin: 2px 8px 10px 0;
  display: inline-block;
}

.shop-single-content .shop-rating-content li .review-star {
  position: relative;
  display: inline-block;
}

.shop-single-content .shop-rating-content li .review-star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.shop-single-content .shop-rating-content li .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.shop-single-content .shop-rating-content li .review-star .star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.shop-single-content .shop-rating-content li a {
  text-decoration: none;
  color: #555555;
  position: relative;
  line-height: 21px;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s linear;
}

.shop-single-content .shop-rating-content li a:hover {
  color: #cc2121;
}

.shop-single-content .product-prices {
  margin-top: 15px;
}

.shop-single-content .product-prices .old-price {
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #b1b1b1;
  margin-right: 8px;
  text-decoration: line-through;
  display: inline-block;
}

.shop-single-content .product-prices .sale-price {
  color: #c40316;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  display: inline-block;
  margin-right: 8px;
}

.shop-single-content .product-prices .discount-percentage {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: white;
  padding: 0 10px 0 10px;
  background: #242424;
  vertical-align: 5px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
}

.shop-single-content .product-description {
  padding-top: 25px;
  border-top: 1px solid #ebebeb;
  margin-top: 30px;
  padding-bottom: 25px;
}

.shop-single-content .product-description ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shop-single-content .product-description ul li {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.shop-single-content .label {
  font-size: 16px;
  color: #242424;
  display: block;
  margin-bottom: 5px;
}

.shop-single-content .product-size .size-select {
  display: inline-block;
  position: relative;
}

.shop-single-content .product-size .size-select select {
  height: 38px;
  padding: 0 8px;
  padding-right: 25px;
  width: 65px;
  appearance: none;
  border: 1px solid #ebebeb;
  outline: none;
}

.shop-single-content .product-size .size-select i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.shop-single-content .product-quantity-cart {
  padding-top: 25px;
}

.shop-single-content .product-quantity {
  position: relative;
  padding-right: 24px;
  margin-right: 30px;
  margin-top: 10px;
}

.shop-single-content .product-quantity button {
  position: absolute;
  background: none;
  top: 0;
  right: 0;
  border: 1px solid #ebebeb;
  padding: 0;
  width: 25px;
  height: 25px;
  font-size: 16px;
}

.shop-single-content .product-quantity input {
  width: 48px;
  height: 50px;
  border: 1px solid #ebebeb;
  padding: 5px 8px;
  font-size: 14px;
}

.shop-single-content .product-quantity *:first-child {
  top: auto;
  bottom: 0;
}

.shop-single-content .product-cart {
  margin-top: 10px;
}

.shop-single-content .product-cart .btn {
  padding: 0 50px;
  font-size: 13px;
}

@media only screen and (max-width: 575px) {
  .shop-single-content .product-cart .btn {
    padding: 0 30px;
  }
}

.shop-single-content .product-table {
  margin-bottom: 25px;
}

.shop-single-content .product-table .table {
  margin-bottom: 0;
}

.shop-single-content .product-table .table tbody tr td {
  padding: 10px 15px;
  width: 33.33%;
  text-align: center;
  border: 1px solid #ebebeb;
  vertical-align: middle;
}

.shop-single-content .product-table .table tbody tr td a {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s linear;
  font-weight: 600;
}

.shop-single-content .product-table .table tbody tr td a:hover {
  color: #cc2121;
}

.shop-single-content .product-table .table tbody tr td .amount {
  font-size: 18px;
  color: #000;
  text-transform: capitalize;
}

.shop-single-content .product-additional-info {
  padding-top: 30px;
  padding-bottom: 15px;
}

.shop-single-content .product-additional-info .social-sharing {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.shop-single-content .product-additional-info .social-sharing li {
  margin-bottom: 5px;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-single-content .product-additional-info .social-sharing li {
    margin-right: 15px;
  }
}

.shop-single-content .product-additional-info .social-sharing li:last-child {
  margin-right: 0;
}

.shop-single-content .product-additional-info .social-sharing li a {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  text-align: center;
  color: #FFF;
  white-space: normal;
  text-indent: 0;
  overflow: hidden;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 3px;
  text-decoration: none;
}

.shop-single-content .product-additional-info .social-sharing li a.facebook {
  background-color: #4867AA;
}

.shop-single-content .product-additional-info .social-sharing li a.twitter {
  background-color: #1DA1F2;
}

.shop-single-content .product-additional-info .social-sharing li a.google {
  background-color: #DD5144;
}

.shop-single-content .product-additional-info .social-sharing li a.pinterest {
  background-color: #BD081B;
}

.shop-single-content .product-additional-info .panel-product-actions {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 10px;
}

.shop-single-content .product-additional-info .panel-product-actions .action-btn {
  padding: 0;
  border: 0;
  background: none;
  line-height: 30px;
  border: none;
  color: #242424;
  font-size: 14px;
  background: transparent;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
}

.shop-single-content .product-reassurance ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shop-single-content .product-reassurance ul li {
  border-bottom: 1px solid #ebebeb;
}

.shop-single-content .product-reassurance ul li .reassurance-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.shop-single-content .product-reassurance ul li .reassurance-item img {
  margin-right: 10px;
}

.shop-tab .nav {
  border-bottom: 1px solid #ebebeb;
  position: relative;
  text-align: center;
}

.shop-tab .nav li a {
  background: transparent;
  border: 0;
  text-transform: capitalize;
  line-height: 26px;
  color: #c2c2c2;
  padding: 0 0 10px 0;
  margin: 0 35px;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  transition: all 300ms linear;
  display: block;
}

@media only screen and (max-width: 767px) {
  .shop-tab .nav li a {
    margin: 0 25px;
  }
}

.shop-tab .nav li a.active, .shop-tab .nav li a:hover {
  color: #242424;
}

.shop-tab .tab-content {
  padding-top: 55px;
}

.shop-tab .tab-content .product-description {
  font-size: 14px;
  color: #5a5a5a;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 55px;
}

.shop-tab .tab-content .product-description ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shop-tab .tab-content .product-details .product-manufacturer {
  margin-bottom: 30px;
}

.shop-tab .tab-content .product-details .product-manufacturer a img {
  padding: 4px;
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: all .2s ease-in-out;
}

.shop-tab .tab-content .product-details .product-reference p,
.shop-tab .tab-content .product-details .product-stock p {
  font-size: 1rem;
  color: #232323;
  font-weight: 700;
  margin-bottom: 8px;
}

.shop-tab .tab-content .product-details .product-reference p span,
.shop-tab .tab-content .product-details .product-stock p span {
  font-size: 14px;
  font-weight: 400;
}

.shop-tab .tab-content .product-details .product-features .date {
  font-size: 16px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 500;
  line-height: 24px;
}

.shop-tab .tab-content .product-details .product-features .data-sheet {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 0;
}

.shop-tab .tab-content .product-details .product-features .data-sheet .name,
.shop-tab .tab-content .product-details .product-features .data-sheet .value {
  flex: 1 0 40%;
  font-weight: 400;
  background: #f1f1f1;
  padding: .625rem;
  margin-right: .625rem;
  min-height: 40px;
  word-break: normal;
  text-transform: capitalize;
  margin-top: 8px;
  margin-bottom: 0;
}

.single-comment {
  display: flex;
}

.single-comment + .single-comment {
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .single-comment {
    display: block;
  }
}

.single-comment .comment-author img {
  border-radius: 50%;
}

.single-comment .comment-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .single-comment .comment-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

.single-comment .comment-content .author-name-rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.single-comment .comment-content .author-name-rating .name {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  color: #6c6c6c;
  margin-bottom: 0;
  line-height: 1;
  margin-right: 10px;
}

.single-comment .comment-content .author-name-rating .review-star {
  position: relative;
  display: inline-block;
}

.single-comment .comment-content .author-name-rating .review-star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.single-comment .comment-content .author-name-rating .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.single-comment .comment-content .author-name-rating .review-star .star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.single-comment .comment-content .date {
  color: #adadad;
  font-size: 14px;
}

.single-comment .comment-content p {
  margin-top: 20px;
}

.review-form {
  margin-top: 55px;
}

.review-form .review-rating {
  display: flex;
  align-items: center;
  padding-top: 35px;
}

.review-form .review-rating .title {
  font-weight: 700;
  font-size: 16px;
  color: #242424;
  margin-right: 15px;
  margin-bottom: 0;
}

.review-form .review-rating ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.review-form .review-rating ul li {
  margin: 0 2px;
  font-size: 16px;
  color: #dbdbdb;
  cursor: pointer;
}

.review-form .review-rating ul li.hover, .review-form .review-rating ul li.selected {
  color: #ffc600;
}

.review-form .form-title {
  font-size: 24px;
  font-weight: 400;
  color: #242424;
}

.review-form .form-check {
  margin-top: 30px;
}

.review-form .form-check .form-check-input {
  margin-top: 3px;
}

/*--
/*  4.11 - Cart CSS
/*----------------------------------------*/
.cart-table .table {
  margin-bottom: 0;
}

.cart-table .table thead tr th {
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-bottom: 0;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
}

.cart-table .table thead tr th.Product-thumb {
  min-width: 150px;
  width: 150px;
}

.cart-table .table thead tr th.Product-info {
  min-width: 330px;
  text-align: left;
}

.cart-table .table thead tr th.Product-quantity {
  min-width: 120px;
}

.cart-table .table thead tr th.Product-total-price {
  min-width: 90px;
}

.cart-table .table thead tr th.Product-add-cart {
  min-width: 120px;
}

.cart-table .table thead tr th.Product-action {
  min-width: 50px;
}

.cart-table .table tbody tr td {
  border: 1px solid #f1f1f1;
  padding: 20px 20px;
  vertical-align: middle;
}

.cart-table .table tbody tr td.Product-info .name a {
  text-decoration: none;
  font-weight: 600;
  line-height: 1;
  color: #242424;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 0.3s linear;
}

.cart-table .table tbody tr td.Product-info .name a:hover {
  color: #cc2121;
}

.cart-table .table tbody tr td.Product-info .product-prices {
  margin-top: 15px;
}

.cart-table .table tbody tr td.Product-info .product-prices .old-price {
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #b1b1b1;
  margin-right: 8px;
  text-decoration: line-through;
  display: inline-block;
}

.cart-table .table tbody tr td.Product-info .product-prices .sale-price {
  color: #cc2121;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  display: inline-block;
  margin-right: 8px;
}

.cart-table .table tbody tr td.Product-info .product-prices .discount-percentage {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #FFF;
  padding: 0 10px 0 10px;
  background: #242424;
  vertical-align: 5px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
}

.cart-table .table tbody tr td.Product-info .product-size-color p {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.cart-table .table tbody tr td.Product-quantity {
  text-align: center;
}

.cart-table .table tbody tr td.Product-quantity .quantity {
  position: relative;
  padding-right: 24px;
}

.cart-table .table tbody tr td.Product-quantity .quantity button {
  position: absolute;
  background: none;
  top: 0;
  right: 0;
  border: 1px solid #ebebeb;
  padding: 0;
  width: 25px;
  height: 25px;
  font-size: 16px;
}

.cart-table .table tbody tr td.Product-quantity .quantity input {
  width: 48px;
  height: 50px;
  border: 1px solid #ebebeb;
  padding: 5px 8px;
  font-size: 14px;
}

.cart-table .table tbody tr td.Product-quantity .quantity *:first-child {
  top: auto;
  bottom: 0;
}

.cart-table .table tbody tr td.Product-total-price {
  text-align: center;
}

.cart-table .table tbody tr td.Product-total-price .price {
  font-size: 18px;
}

.cart-table .table tbody tr td.Product-add-cart {
  text-align: center;
}

.cart-table .table tbody tr td.Product-action {
  text-align: center;
}

.cart-table .table tbody tr td.Product-action button {
  text-decoration: none;
  font-size: 20px;
  padding: 0;
  border: 0;
  background: none;
}

.cart-table .table tbody tr td.Product-action button:hover {
  color: #cc2121;
}

.cart-btn {
  border: 1px solid #f1f1f1;
  padding: 10px 20px 20px;
  border-top: 0;
}

@media only screen and (max-width: 575px) {
  .cart-btn {
    padding: 10px 10px 20px;
  }
}

.cart-btn .btn {
  margin-top: 10px;
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .cart-btn .btn {
    margin-right: 5px;
  }
}

.cart-btn .btn:last-child {
  margin-left: 0;
}

.cart-shipping,
.cart-totals {
  margin-top: 48px;
}

.cart-title .title {
  color: #242424;
  font-size: 24px;
  text-transform: none;
  margin-bottom: 15px;
}

.cart-totals .cart-total-table {
  background-color: #f7f7f7;
  padding: 10px 25px;
  margin-bottom: 30px;
}

.cart-totals .cart-total-table .table {
  margin-bottom: 0;
}

.cart-totals .cart-total-table .table tbody tr td {
  padding: 5px 0;
  border-top: 0;
}

.cart-totals .cart-total-table .table tbody tr:last-child td {
  border-bottom: 1px;
}

.cart-totals .cart-total-table .shipping-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.cart-totals .cart-total-table .value {
  font-size: 14px;
  font: 400px;
  color: #000;
  text-transform: capitalize;
}

.cart-totals .cart-total-table .price {
  font-size: 14px;
  font: 400px;
  color: #000;
}

.empty-cart .empty-cart-title {
  color: #242424;
  font-size: 35px;
}

@media only screen and (max-width: 767px) {
  .empty-cart .empty-cart-title {
    font-size: 24px;
  }
}

.empty-cart .empty-cart-img {
  width: 110px;
  margin: 0 auto;
  padding-top: 25px;
}

.empty-cart p {
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 30px;
  color: #242424;
}

.empty-cart .btn {
  margin-top: 20px;
}

/*--
/*  4.12 - Compare CSS
/*----------------------------------------*/
.compare-table .table {
  margin-bottom: 0;
}

.compare-table .table tbody tr th {
  white-space: nowrap;
  text-align: center;
  padding: 15px 15px;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 700;
  color: #242424;
  vertical-align: middle;
  width: 160px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th {
    padding: 20px 15px;
  }
}

.compare-table .table tbody tr td {
  padding: 15px 15px;
  text-align: center;
  border: 1px solid #e6e6e6;
  color: #242424;
  font-size: 18px;
  min-width: 250px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 20px 15px;
    font-size: 16px;
  }
}

.compare-table .table tbody tr td .product-image-title .product-image img {
  display: block;
}

.compare-table .table tbody tr td .product-image-title .category {
  color: #555;
  font-size: 15px;
  display: block;
  margin-top: 25px;
  text-decoration: none;
}

.compare-table .table tbody tr td .product-image-title .category:hover {
  color: #cc2121;
}

.compare-table .table tbody tr td .product-image-title .title {
  margin-bottom: 0;
}

.compare-table .table tbody tr td .product-image-title .title a {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  margin-top: 15px;
  text-decoration: none;
  margin-top: 5px;
}

.compare-table .table tbody tr td .description {
  font-size: 14px;
}

.compare-table .table tbody tr td .main-btn {
  height: 50px;
  line-height: 50px;
  letter-spacing: 0;
}

.compare-table .table tbody tr td .delete {
  font-size: 18px;
  color: #242424;
  padding: 0;
  border: 0;
  background: none;
}

.compare-table .table tbody tr td .delete:hover {
  color: #cc2121;
}

.compare-table .table tbody tr td .review-star {
  position: relative;
  display: inline-block;
}

.compare-table .table tbody tr td .review-star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

.compare-table .table tbody tr td .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.compare-table .table tbody tr td .review-star .star::before {
  content: "\e9d5" "\e9d5" "\e9d5" "\e9d5" "\e9d5";
  font-family: 'plazaicon';
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

/*--
/*  4.13 - Checkout CSS
/*----------------------------------------*/
.checkout-info {
  margin-top: 30px;
}

.checkout-info .info-header {
  padding: 14px 30px;
  margin: 0;
  position: relative;
  background-color: #eeeff2;
  list-style: none outside !important;
  width: auto;
  word-wrap: break-word;
  color: #000;
  font-size: 14px;
}

.checkout-info .info-header strong {
  font-weight: 700;
  color: #000;
}

.checkout-info .info-header i {
  font-size: 12px;
  color: #6dde6d;
  margin-right: 10px;
}

.checkout-info .info-header a {
  color: #000;
  transition: all 0.3s linear;
}

.checkout-info .info-header a:hover {
  color: #cc2121;
}

.checkout-info .info-header.error i {
  color: #c43002;
}

.checkout-info .card-body {
  padding: 0;
  padding-top: 20px;
}

.checkout-info .card-body p {
  font-size: 14px;
  color: #212529;
  margin-bottom: 0;
}

.checkout-info .card-body .single-form {
  margin-top: 15px;
}

.checkout-info .card-body .single-form .form-check {
  margin-left: 20px;
}

.checkout-info .card-body .single-form .form-check .form-check-input {
  margin-top: 5px;
}

.checkout-info .card-body .forget {
  margin-top: 15px;
}

.checkout-info .card-body .forget a {
  font-weight: 400;
  color: #212529;
  transition: all 0.3s linear;
  text-decoration: none;
}

.checkout-info .card-body .forget a:hover {
  color: #cc2121;
}

.checkout-title .title {
  font-size: 24px;
  color: #242424;
  position: relative;
}

.checkout-title .title::after {
  content: "";
  width: 50px;
  display: block;
  margin-top: 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: inherit;
}

.checkout-form {
  margin-top: 48px;
}

.checkout-form .checkout-checkbox {
  margin-bottom: 0;
  min-height: inherit;
  margin-top: 30px;
}

.checkout-form .checkout-checkbox .form-check-input {
  margin-top: 5px;
}

.checkout-account {
  display: none;
}

.checkout-shipping {
  display: none;
}

.checkout-note .form-control {
  border: 0;
  background-color: #f7f7f7;
}

.checkout-order {
  border: 2px solid #ebebeb;
  padding: 40px;
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .checkout-order {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .checkout-order {
    padding: 20px;
  }
}

.checkout-order ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.checkout-order .table {
  margin-bottom: 0;
}

.checkout-order .table thead tr th {
  padding: 10px 0;
  border-top: 0;
  border-bottom: 1px solid #e1e1e1;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  vertical-align: middle;
}

.checkout-order .table thead tr th.Product-price {
  text-align: right;
}

.checkout-order .table tbody tr td {
  padding: 5px 0;
  border-top: 0;
  vertical-align: middle;
}

.checkout-order .table tbody tr td p {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.checkout-order .table tbody tr td.Product-price {
  text-align: right;
}

.checkout-order .table tbody tr:first-child td {
  padding-top: 20px;
}

.checkout-order .table tbody tr:last-child td {
  padding-bottom: 20px;
}

.checkout-order .table tfoot tr td {
  padding: 10px 0;
  vertical-align: middle;
}

.checkout-order .table tfoot tr td p {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.checkout-order .table tfoot tr td.Product-price {
  text-align: right;
}

.checkout-order .table tfoot tr td.total-price {
  text-align: right;
}

.checkout-order .table tfoot tr td.total-price p {
  font-size: 18px;
}

.checkout-order .table tfoot tr td .cus-radio label {
  white-space: nowrap;
}

.checkout-payment {
  margin-top: 30px;
}

.checkout-payment ul li {
  margin-bottom: 3px;
}

.checkout-payment ul li :last-child {
  margin-bottom: 0;
}

.checkout-payment .single-payment .payment-radio [type="radio"] {
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.checkout-payment .single-payment .payment-radio label .payment {
  width: 100px;
  padding: 0 2px;
}

.checkout-payment .single-payment .payment-radio label a {
  color: #000;
  text-decoration: underline;
  text-transform: capitalize;
}

.checkout-payment .single-payment .payment-radio .payment-details {
  display: none;
  padding: 10px 0;
}

.checkout-payment .single-payment .payment-radio .payment-details p {
  font-size: 14px;
}

.checkout-payment .checkout-btn {
  margin-top: 20px;
}

/*--
/*  4.14 - My Account CSS
/*----------------------------------------*/
.my-account-menu .account-menu-list {
  background-color: #f7f7f7;
}

.my-account-menu .account-menu-list li a {
  width: 100%;
  padding: 10px 30px;
  font-size: 14px;
  color: #212529;
  margin-top: -1px;
  transition: all 0.3s linear;
  display: block;
  text-decoration: none;
}

.my-account-menu .account-menu-list li a i {
  margin-right: 5px;
}

.my-account-menu .account-menu-list li a:hover, .my-account-menu .account-menu-list li a.active {
  background-color: #cc2121;
  color: #FFF;
}

.my-account-tab .account-title {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  font-family: "Karla", sans-serif;
}

.account-table .table thead tr th {
  border: 1px solid #ECECEC;
  border-bottom-color: #ECECEC !important;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 8px 15px;
}

.account-table .table thead tr th.name {
  min-width: 140px;
}

.account-table .table thead tr th.date {
  min-width: 130px;
}

.account-table .table tbody tr td {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  vertical-align: middle;
  border: 1px solid #ECECEC;
  padding: 0;
}

.account-table .table tbody tr td a {
  padding: 0 15px;
  height: 45px;
  line-height: 45px;
  color: #000;
}

.my-account-dashboard .welcome-dashboard {
  margin-top: 10px;
}

.my-account-dashboard .welcome-dashboard p {
  font-size: 14px;
}

.my-account-dashboard .welcome-dashboard p strong {
  font-weight: 500;
}

.my-account-dashboard .welcome-dashboard p a {
  color: #000;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.my-account-dashboard .welcome-dashboard p a:hover {
  color: #cc2121;
}

.my-account-payment p {
  font-weight: 500;
  font-size: 14px;
}

.my-account-address .account-address .name {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  font-family: "Karla", sans-serif;
}

.my-account-address .account-address p {
  margin-top: 20px;
  font-size: 14px;
}

.my-account-address .account-address .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 3px;
  font-weight: 400;
}

.my-account-details .account-details .title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: "Karla", sans-serif;
}

.my-account-details .account-details .single-form .btn {
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
}

/*--
/*  4.15 - Login & Register CSS
/*----------------------------------------*/
.login-register-wrapper .title {
  font-size: 24px;
  font-weight: 700;
  color: #242424;
}

.login-register-wrapper .form-check {
  margin-bottom: 0;
  min-height: inherit;
  margin-top: 30px;
}

.login-register-wrapper .form-check .form-check-input {
  margin-top: 5px;
}

.login-register-wrapper .single-form .btn {
  width: 160px;
  margin-bottom: 20px;
}

.login-register-wrapper p a {
  text-decoration: none;
}

/*--
/*  4.16 - Blog Details CSS
/*----------------------------------------*/
.blog-details {
  margin-top: 50px;
}

.blog-details img {
  width: 100%;
}

.blog-details .blog-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: #292929;
  text-transform: capitalize;
  position: relative;
  display: block;
  margin-bottom: 22px;
  margin-top: 26px;
  transition: all 0.3s linear;
  text-decoration: none;
}

@media only screen and (max-width: 575px) {
  .blog-details .blog-title {
    font-size: 18px;
  }
}

.blog-details .blog-meta {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.blog-details .blog-meta > p {
  font-size: 14px;
  color: #9d9d9d;
  margin-bottom: 0;
}

.blog-details .blog-meta > p + p::before {
  content: "/";
  display: inline-block;
  margin: 0 7px;
}

.blog-details .blog-meta > p a {
  text-decoration: none;
  color: #cc2121;
}

.blog-details .dec-img-wrapper .dec-img {
  margin-top: 30px;
}

.blog-dec-tags-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 35px;
}

.blog-dec-tags-social span {
  font-size: 16px;
  font-weight: 600;
  color: #242424;
}

.blog-dec-tags-social .blog-dec-tags {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.blog-dec-tags-social .blog-dec-tags .tags {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-dec-tags-social .blog-dec-tags .tags li {
  margin-left: 5px;
  margin-top: 5px;
}

.blog-dec-tags-social .blog-dec-tags .tags li a {
  height: 30px;
  padding: 0 10px;
  line-height: 28px;
  border: 1px solid #e1e1e1;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  transition: all 0.3s linear;
  display: block;
  text-decoration: none;
}

.blog-dec-tags-social .blog-dec-tags .tags li:hover a {
  background-color: #cc2121;
  border-color: #cc2121;
  color: #FFF;
}

.blog-dec-tags-social .blog-dec-social {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.blog-dec-tags-social .blog-dec-social .social {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-dec-tags-social .blog-dec-social .social li {
  margin-left: 15px;
}

.blog-dec-tags-social .blog-dec-social .social li a {
  font-size: 16px;
  color: #212529;
  transition: all 0.3s linear;
  display: block;
  text-decoration: none;
}

.blog-dec-tags-social .blog-dec-social .social li:hover a {
  color: #cc2121;
}

.blog-next-previous {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #e1e1e1;
}

.blog-next-previous .left,
.blog-next-previous .right {
  font-size: 14px;
  font-weight: 600;
  color: #242424;
}

.blog-next-previous .left a,
.blog-next-previous .right a {
  color: #242424;
  text-decoration: none;
  transition: all 0.3s linear;
}

.blog-next-previous .left a:hover,
.blog-next-previous .right a:hover {
  color: #cc2121;
}

.blog-next-previous .left i,
.blog-next-previous .right i {
  font-size: 13px;
}

.blog-comment .comment-wrapper .single-form textarea {
  height: 180px;
  padding-top: 10px;
}

.blog-comment .comment-title {
  text-transform: uppercase;
  font-size: 18px;
  color: #242424;
  font-weight: 600;
}

.blog-comment ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-comment .comment-items {
  padding-top: 25px;
}

.blog-comment .comment-items li {
  margin-top: 30px;
}

.blog-comment .comment-items .comment-replay {
  padding-left: 150px;
}

@media only screen and (max-width: 767px) {
  .blog-comment .comment-items .comment-replay {
    padding-left: 0;
  }
}

.blog-comment .comment-items .comment-replay .single-comment {
  background-color: #f7f7f7;
  padding: 15px 15px 12px;
}

.single-comment {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .single-comment {
    display: block;
  }
}

.single-comment .comment-avater img {
  border-radius: 50%;
  width: 120px;
}

.single-comment .comment-content {
  padding-left: 30px;
  flex: 1;
}

@media only screen and (max-width: 575px) {
  .single-comment .comment-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

.single-comment .comment-content .avater-name {
  font-size: 16px;
  font-weight: 600;
  color: #242424;
}

.single-comment .comment-content .date {
  font-size: 14px;
  font-weight: 400;
  color: #212529;
}

.single-comment .comment-content p {
  margin-top: 15px;
}

.single-comment .comment-content .replay {
  padding-left: 48px;
  position: relative;
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.single-comment .comment-content .replay::before {
  background-color: #242424;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  width: 37px;
}

/*--
/*  4.17 - Contact CSS
/*----------------------------------------*/
.single-contact-info {
  display: flex;
  margin-top: 30px;
}

.single-contact-info .info-icon i {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 1px solid #ebebeb;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: #242424;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info .info-icon i {
    font-size: 16px;
    width: 35px;
    height: 35px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-icon i {
    font-size: 16px;
    width: 45px;
    height: 45px;
    line-height: 43px;
  }
}

.single-contact-info .info-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info .info-content {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-content {
    padding-left: 20px;
  }
}

.single-contact-info .info-content .title {
  font-size: 20px;
  font-weight: 700;
  color: #cc2121;
  margin-bottom: 15px;
}

.single-contact-info .info-content p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info .info-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-content p {
    font-size: 14px;
  }
}

.single-contact-info .info-content p a {
  text-decoration: none;
  color: #212529;
  transition: all 0.3s linear;
}

.single-contact-info .info-content p a:hover {
  color: #cc2121;
}

.form-message {
  margin-bottom: 0;
}

.form-message.error, .form-message.success {
  font-size: 16px;
  color: #07294d;
  background: #ddd;
  padding: 10px 15px;
  margin-left: 15px;
  margin-top: 15px;
}

.form-message.error {
  color: red;
}

/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/
/*--
/*  5.1 - Footer Widget CSS
/*----------------------------------------*/
.footer-widget {
  margin-top: 30px;
}

.footer-widget .footer-widget-text {
  padding-top: 30px;
}

.footer-widget .footer-widget-text .desc {
  margin-bottom: 50px;
}

.footer-widget .footer-widget-text p {
  font-size: 14px;
  color: #ccc;
}

.footer-widget .footer-widget-text p strong {
  color: #FFF;
  text-transform: uppercase;
}

.footer-widget .widget-link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-widget .widget-link li a {
  font-size: 14px;
  line-height: 36px;
  padding: 0;
  display: block;
  text-transform: capitalize;
  color: #ccc;
  margin-bottom: 0px;
  display: block;
  position: relative;
  transition: all 300ms linear;
  text-decoration: none;
}

.footer-widget .widget-link li a:hover {
  padding-left: 10px;
  color: #cc2121;
}

.footer-widget .widget-newsletter p {
  font-size: 14px;
  color: #ccc;
}

.footer-widget .widget-newsletter .input-wrapper {
  position: relative;
}

.footer-widget .widget-newsletter .input-wrapper input {
  height: 45px;
  background: #2b2b2b;
  border: 0;
  color: #FFF;
  padding: 10px 100px 10px 20px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  border: none;
  border-radius: 25px;
}

.footer-widget .widget-newsletter .input-wrapper input::placeholder {
  color: #ccc;
  opacity: 1;
}

.footer-widget .widget-newsletter .input-wrapper button {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  font-size: 0;
  font-weight: 700;
  font-size: 12px;
  color: #FFF;
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0 20px;
  line-height: 48px;
  height: 45px;
  transition: all 300ms linear;
}

.footer-widget .widget-newsletter .input-wrapper button:hover {
  color: #cc2121;
}

.footer-widget .widget-social {
  margin-top: 40px;
}

.footer-widget .widget-social ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-widget .widget-social ul li {
  margin-right: 8px;
  margin-top: 8px;
}

.footer-widget .widget-social ul li:last-child {
  margin-right: 0;
}

.footer-widget .widget-social ul li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  text-decoration: none;
  color: #FFF;
  transition: all 0.3s linear;
}

.footer-widget .widget-social ul li a.facebook {
  background-color: #4867AA;
}

.footer-widget .widget-social ul li a.twitter {
  background-color: #1DA1F2;
}

.footer-widget .widget-social ul li a.youtube {
  background-color: #FE0000;
}

.footer-widget .widget-social ul li a.instagram {
  background-color: #B23A94;
}

.footer-widget .widget-social ul li a.linkedin {
  background-color: #007BB6;
}

.footer-widget .widget-social ul li a:hover {
  opacity: 0.8;
}

.footer-widget-title {
  font-size: 20px;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 30px;
  letter-spacing: 0;
  position: relative;
  color: #FFF;
  font-weight: 700;
}

/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/
.sidebar-widget {
  padding-top: 20px;
}

.categories-widget {
  margin-top: 30px;
}

.categories-widget .category-top-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget .category-top-menu > li > a {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #242424;
  margin-bottom: 20px;
  position: relative;
  display: block;
  padding-bottom: 15px;
  text-transform: capitalize;
  text-decoration: none;
}

.categories-widget .category-top-menu .category-sub-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget .category-top-menu .category-sub-menu li {
  position: relative;
}

.categories-widget .category-top-menu .category-sub-menu li a {
  font-weight: 400;
  color: #242424;
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 34px;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  position: relative;
  transition: all 0.3s linear;
}

.categories-widget .category-top-menu .category-sub-menu li a span {
  float: right;
  display: block;
}

.categories-widget .category-top-menu .category-sub-menu li a:hover {
  color: #cc2121;
}

.categories-widget .category-top-menu .category-sub-menu li .category-toggle {
  width: 28px;
  height: 28px;
  font-size: 18px;
  color: #242424;
  position: absolute;
  top: 2px;
  right: 0;
  cursor: pointer;
}

.categories-widget .category-top-menu .category-sub-menu li .category-toggle .add,
.categories-widget .category-top-menu .category-sub-menu li .category-toggle .remove {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  line-height: 28px;
}

.categories-widget .category-top-menu .category-sub-menu li .category-toggle .add {
  display: none;
}

.categories-widget .category-top-menu .category-sub-menu li .category-toggle.collapsed .remove {
  display: none;
}

.categories-widget .category-top-menu .category-sub-menu li .category-toggle.collapsed .add {
  display: block;
}

.categories-widget .category-top-menu .category-sub-menu li ul {
  padding-left: 5%;
}

.categories-widget .category-top-menu .category-sub-menu li ul li ul {
  padding-left: 10%;
}

.categories-widget .category-top-menu .category-sub-menu li ul li ul li ul {
  padding-left: 15%;
}

.filter-widget {
  margin-top: 30px;
}

.filter-title {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #242424;
  position: relative;
  display: block;
  padding-top: 55px;
  border-top: 1px solid #e5e5e5;
  text-transform: uppercase;
  margin-bottom: 0;
}

.widget-title {
  display: block;
  clear: both;
  color: #242424;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin-bottom: 35px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.widget-item {
  padding-top: 30px;
}

.widget-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 20px;
}

.widget-item ul li {
  margin-top: 22px;
}

.widget-item ul li > label {
  display: flex;
  align-items: center;
}

.widget-item ul li > label .form-check {
  padding: 0;
  display: inline-block;
  min-height: inherit;
  margin-bottom: 0;
}

.widget-item ul li > label .form-check .form-check-input {
  margin: 0;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.widget-item ul li > label a {
  display: block;
  text-decoration: none;
  flex: 1;
  padding-left: 7px;
  color: #242424;
  font-size: 14px;
}

.widget-item .widget-price {
  padding-top: 38px;
}

.widget-item .widget-price input {
  font-size: 14px;
  color: #242424;
  width: 100%;
  padding: 0;
  border: 0;
  background: none;
}

.widget-item .widget-price .ui-slider {
  height: 6px;
  margin-top: 15px;
  margin-bottom: 6px;
}

.widget-item .widget-price .ui-slider .ui-slider-range {
  background-color: #cc2121;
  border-color: #cc2121;
}

.widget-item .widget-price .ui-slider .ui-slider-handle {
  border: 1px solid #555;
  width: 6px;
  height: 18px;
  top: -7px;
  margin-left: -4px;
}

.widget-item .widget-price .ui-slider .ui-slider-handle.ui-state-active {
  background-color: #FFF;
}

.widget-tags {
  padding-top: 45px;
  border-top: 1px solid #ebebeb;
  margin-top: 30px;
}

.widget-tags .widget-tags-items {
  margin-left: -5px;
  margin-right: -5px;
}

.widget-tags .widget-tags-items a {
  display: block;
  float: left;
  padding: 0 23px;
  font-size: 14px;
  line-height: 38px;
  color: #242424;
  font-weight: 400;
  border: 1px solid #ebebeb;
  border-radius: 25px;
  margin: 10px 5px 0;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s linear;
}

.widget-tags .widget-tags-items a:hover {
  background-color: #cc2121;
  border-color: #cc2121;
  color: #FFF;
}

.widget-search .single-form {
  position: relative;
}

.widget-search .single-form .form-control {
  padding-right: 45px;
}

.widget-search .single-form button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border: 0;
  background: none;
  font-size: 18px;
  color: #242424;
}

.widget-post {
  margin-top: 25px;
}

.widget-post .widget-post-items {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-top: 15px;
}

.widget-post .single-post-item {
  display: flex;
  margin-top: 30px;
}

.widget-post .single-post-item .post-thumb a img {
  width: 90px;
}

.widget-post .single-post-item .post-content {
  flex: 1;
  padding-left: 20px;
}

.widget-post .single-post-item .post-content .post-title a {
  font-size: 14px;
  color: #242424;
  text-decoration: none;
  transition: all 0.3s linear;
  font-weight: 600;
}

.widget-post .single-post-item .post-content .post-title a:hover {
  color: #cc2121;
}

.widget-post .single-post-item .post-content .date {
  font-size: 13px;
  color: #707070;
}

/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
.footer-bg {
  background: #252525;
}

.copyright-section {
  padding: 30px 0 40px;
  border-top: 1px solid #303030;
}

.copyright-payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.copyright-text {
  margin-top: 10px;
}

.copyright-text p {
  line-height: 30px;
  color: #ccc;
  font-size: 14px;
}

.copyright-text p a {
  color: #cc2121;
  text-decoration: none;
}

.payment-method {
  margin-top: 10px;
}

.back-to-top {
  background: #cc2121;
  color: #FFF;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  display: none;
  text-align: center;
  text-decoration: none;
  transition: all 500ms ease-in-out;
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-size: 18px;
  z-index: 28;
}

@media only screen and (max-width: 767px) {
  .back-to-top {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.back-to-top:hover {
  background: #242424;
  color: #FFF;
  box-shadow: 0 0 20px #FFF;
}
