/*--
/*  2.4 - Quick View CSS
/*----------------------------------------*/


.modal{
    &.fade {
        display: block !important;
        opacity: 0;
        visibility: hidden;

        &.show {
            display: block !important;
            opacity: 1;
            visibility: visible;
        }
    }
    & .modal-dialog{
        width: calc(100% - 30px);
        max-width: 64rem;
        
        & .modal-content{
            position: relative;
            border: 0;
            & .btn-close{
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 7;
                &:focus {
                    box-shadow: none;
                }
            }
            & .modal-body{
                padding: 30px;
                @media #{$small-mobile}{
                    padding: 30px 20px 20px;
                }

                & .quick-view-image{
                    margin-top: 30px;
                }
                & .quick-view-content{
                    margin-top: 30px;
                }
            }
        }
    }
}




// vertical slider wrap
.vertical-slider-wrap{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-top: 50px;
    @media #{$small-mobile}{
        display: block;
    }
    & .quick-view-slider{
        flex-basis: 90%;
        max-width: 85%;
        margin-left: 30px;
        cursor: crosshair;
        position: relative;

        @media #{$small-mobile}{
            flex-basis: 100%;
            max-width: 100%;
            margin-left: 0;
        }

        & .product-label{
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 7;

            @media #{$small-mobile}{
                top: 5px;
                left: 5px;
            }

            & span{
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                color: $white;
                padding: 0;
                font-size: 12px;
                font-weight: 400;
                text-transform: capitalize;
                z-index: 4;                    
            }
            & .new{
                background-color: #1759df;
            }
            & .discount-percentage{
                background-color: $primary;
            }
        }        
        &:hover{
            & .btn-gallery{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    & .quick-view-slider-nav{
        flex-basis: 15%;
        max-width: 15%;
        height: 100%;

        @media #{$small-mobile}{
            flex-basis: 100%;
            max-width: 100%;
            margin-top: 10px;
        }

        & .swiper-wrapper{
            height: 100%;
           & .swiper-slide{
                height: 100% !important;
                opacity: 0.5;
                cursor: pointer;
                &.swiper-slide-thumb-active{
                    opacity: 1;
                }
            }
        }
    }
    &.vt-right-side{
        flex-direction: unset;
    }
}






