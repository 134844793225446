/*--
/*  4.7 - Testimonial CSS
/*----------------------------------------*/

// Testimonial Section
.testimonial-section{}

// Single Testimonial
.single-testimonial{
    max-width: 870px;
    margin: 0 auto;

    & .testimonial-avater{
        & img{
            border-radius: 50%;
            display: inline-block;
        }
    }
    & .testimonial-content{
        padding-top: 35px;

        & p{
            font-size: 16px;
            font-weight: 400;
            line-height: 2.125;
            text-align: center;
            margin-bottom: 30px;

            @media #{$small-mobile}{
                font-size: 14px;
            }
        }
        & .name{
            color: #292929;
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }
        & .designation{
            text-align: center;
            color: #929292;
            font-size: 14px;
            margin-top: 15px;            
        }
    }
}

// Testimonial Active
.testimonial-active{
    position: relative;

    // Swiper Button Next & Prev
    & .swiper-button-next,
    & .swiper-button-prev{
        color: $dark;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;

        &::after{
            font-size: 36px;

            @media #{$small-mobile}{
                font-size: 20px;
            }
        }
    }

    & .swiper-button-next{}

    &:hover{
        // Swiper Button Next & Prev
        & .swiper-button-next,
        & .swiper-button-prev{
            opacity: 1;
            visibility: visible;
        }
        & .swiper-button-prev{
            left: 30px;
        }
        & .swiper-button-next{
            right: 30px;
        }
    }
}





