/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/


// Single Slider
.single-slider{
    height: 936px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-size: cover;

    @media #{$laptop-device}{
        height: 820px;
    }
    @media #{$desktop-device}{
        height: 720px;
    }
    @media #{$tablet-device}{
        height: 680px;
    }
    @media #{$large-mobile}{
        height: 580px;
    }
}

// Slider Content
.slider-content{
    max-width: 630px;
    padding-top: 135px;
    opacity: 0;

    @media #{$desktop-device}{
        max-width: 430px;
    }

    @media #{$tablet-device}{
        padding-top: 114px;
        max-width: 480px;
    }
    @media #{$large-mobile}{
        max-width: 70%;
        padding-top: 125px;
    }
    @media #{$small-mobile}{
        max-width: 100%;
    }

    & .sub-title{
        font-size: 24px;
        color: #666666;
        font-weight: 400;
        line-height: 1;
        text-transform: capitalize;
        margin-bottom: 35px;  

        @media #{$desktop-device}{
            font-size: 20px;
        }
        @media #{$desktop-device}{
            font-size: 20px;
        }
        @media #{$large-mobile}{
            font-size: 16px;
        }
    }
    & .main-title{
        font-size: 80px;
        color: $dark;
        font-weight: 400;
        line-height: 1;   
        margin-bottom: 35px;
        
        @media #{$desktop-device}{
            font-size: 54px;
        }
        @media #{$tablet-device}{
            font-size: 48px;
        }
        @media #{$large-mobile}{
            font-size: 38px;
        }
    }
    & p{
        font-size: 21px;
        color: #666;
        font-weight: 400;
        line-height: 1.4;        
        margin-bottom: 60px;

        @media #{$desktop-device}{
            font-size: 18px;
        }
        @media #{$tablet-device}{
            font-size: 18px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
            margin-bottom: 40px;
        }
    }
    & .btn{
        text-transform: uppercase;
        border-radius: 100px;
        height: 65px;
        line-height: 63px;
        font-weight: 700;
        padding: 0 50px;
        font-size: 20px;

        @media #{$desktop-device}{
            height: 55px;
            line-height: 53px;
            padding: 0 30px;
            font-size: 16px;
        }
        @media #{$tablet-device}{
            height: 55px;
            line-height: 53px;
            padding: 0 30px;
            font-size: 16px;
        }
        @media #{$large-mobile}{
            height: 45px;
            line-height: 43px;
            padding: 0 30px;
            font-size: 14px;
        }
    }
}

// Slider Content 02
.slider-content-02{
    & .sub-title{
        color: $white;
    }
    & .main-title{
        color: $white;
    }
    & p{
        color: $white;
    }
    & .btn{}
}

// Slider Content 03
.slider-content-03{
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 90px;

    @media #{$tablet-device}{
        padding-bottom: 60px;
    }
    @media #{$large-mobile}{
        padding-bottom: 30px;
    }

    & .sub-title{
        color: $white;
    }
    & .main-title{
        color: $white;
    }
    & p{
        color: $white;
    }
    & .btn{}
}

// Slider Active
.slider-active{

    // Swiper Pagination
    & .swiper-pagination {
        bottom: 30px;

        & .swiper-pagination-bullet{
            width: 7px;
            height: 7px;
            transform: scale(0.8);
            background-color: #d4d4d4;
            opacity: 1;
            transition: $baseTransition;

            &.swiper-pagination-bullet-active{
                transform: scale(1.3);
                background-color: $primary;
            }

            &:hover{
                transform: scale(1.3);
                background-color: $primary;
            }
        }
    }

    // Swiper Button Next & Prev
    & .swiper-button-next,
    & .swiper-button-prev{
        color: $white;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;

        &::after{
            font-size: 48px;

            @media #{$tablet-device}{
                font-size: 36px;
            }
            @media #{$large-mobile}{
                font-size: 24px;
            }
        }
    }

    & .swiper-button-next{}

    &:hover{
        // Swiper Button Next & Prev
        & .swiper-button-next,
        & .swiper-button-prev{
            opacity: 1;
            visibility: visible;
        }
        & .swiper-button-prev{
            left: 30px;
        }
        & .swiper-button-next{
            right: 30px;
        }
    }
}

// Single Slider Animation
.animation-style-01{
    &.swiper-slide-active{
        & .slider-content{
            opacity: 1;

            & .sub-title{
                animation-name: fadeInUp;
                animation-delay: 0.5s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
                
            }
            & .main-title{
                animation-name: fadeInUp;
                animation-delay: 1s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & p{
                animation-name: fadeInUp;
                animation-delay: 1.5s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .btn{
                animation-name: fadeInLeft;
                animation-delay: 2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }
    }
}



