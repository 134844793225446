/*--
/*  2.3 - Blockquote CSS
/*----------------------------------------*/

blockquote {
    background-color: #f6f6f6;
    padding: 30px 45px;
    margin: 31px 0 31px 40px;
    border: 1px solid #ebebeb;
    border-left: 4px solid $primary;
    position: relative;

    &::before{
        content: "\eb0c";
        font-family: 'plazaicon';
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 50px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        color: $primary;

        @media #{$small-mobile}{
            font-size: 30px;
        }
    }

    @media #{$small-mobile}{
        margin-left: 0;
        padding: 20px 25px;
    }

    & p {
        font-size: 16px;
        font-style: italic;
        color: $body-color;
        line-height: 24px;
        font-weight: 400;
    }
}