/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

// Footer BG 
.footer-bg {
	background: #252525;
}

// copyright section 
.copyright-section{
    padding: 30px 0 40px;
    border-top: 1px solid #303030;
}


// copyright payment method
.copyright-payment-method{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.copyright-text{
    margin-top: 10px;

    & p{
        line-height: 30px;
        color: #ccc;
        font-size: 14px;
        
        & a{
            color: $primary;
            text-decoration: none;
        }
    }
}

.payment-method{
    margin-top: 10px;
}


// Back to Top 
.back-to-top{
    background: $primary;
    color: $white;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 100%;
    display: none;
    text-align: center;
    text-decoration: none;
    transition: all 500ms ease-in-out;
    position: fixed;
    right: 50px;
    bottom: 50px;
    font-size: 18px;
    z-index: 28;

    @media #{$large-mobile}{
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    &:hover{
        background: #242424;
        color: $white;
        box-shadow: 0 0 20px $white;
    }
}

