/*--
/*  4.8 - Page Banner CSS
/*----------------------------------------*/

// Page Banner Section 
.page-banner-section{
    background-color: #f3f3f3;
    padding-top: 225px;
    padding-bottom: 60px;
}

.page-banner-content{
    & .page-banner-title{
        font-size: 40px;
        color: #242424;
        text-transform: capitalize;
        line-height: 1;     
        
        @media #{$tablet-device}{
            font-size: 30px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & .breadcrumb{
        margin-bottom: 0;
        border-radius: 0;
        padding: 0;
        background: none;
        margin-top: 25px;

        & .breadcrumb-item{
            & + .breadcrumb-item{
                padding-left: 0;
                &::before {
                    content: "\e930";
                    color: #666;
                    margin: 0 15px;
                    font-size: 14px;
                    vertical-align: -1px;
                    font-family: 'plazaicon';
                    padding: 0;
                }
            }
            &.active{}
            & a{
                text-decoration: none;
                display: inline-block;
                position: relative;
                color: #666;
                font-size: 14px;
                line-height: 1.5;  
                transition: all 0.3s linear;     
                
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}














