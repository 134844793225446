/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/

@import "page/slider";
@import "page/features";
@import "page/about";
@import "page/product";
@import "page/banner";
@import "page/blog";
@import "page/testimonial";
@import "page/page-banner";
@import "page/shop/shop";
@import "page/shop/shop-single";
@import "page/cart";
@import "page/compare";
@import "page/checkout";
@import "page/my-account";
@import "page/login";
@import "page/blog-details";
@import "page/contact";
